
ul.small-list {
  padding-left: 0px;
  line-height: 6px;
  margin-top: 10px;
}
a#sep {
  margin-left: 10px;
}
.cont-box p{
  margin-bottom: 0px;
}
















@media (min-width: 1400px)
{
.container {
    max-width: 1380px;}
}
@media (max-width: 1300px) and (min-width: 1200px){

  .container {
      max-width: 1120px;
  }
}


.Getd {
  margin-top: 30px;
  margin-bottom: 30px;
}
img.thankyou {
  width: 16%;
  margin-bottom: 30px;
}
section.thankyou {
  position: relative;
  background-image: linear-gradient(180deg, #FFFFFF 0%, #E0ECF0 100%);
  padding-top: 230px;
  text-align: center;
  padding-bottom: 80px;
}
marquee {
  text-align: center;
  color: #fff !important;
  float: none;
}
.Getd a {
  background: #3eafb7;
  border: 1px solid #3eafb7;
  color: #fff;
  padding: 13px 22px;
  text-transform: uppercase;
  font-size: 15px;
}
@media (max-width: 1600px) and (min-width: 1401px){
  .container {
    max-width: 1280px;
}
}
.banner-content .awesome {
  font-family: futura;
  font-style: italic;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  color: #313131;
  font-size: 90px;
  font-weight: bold;
  animation: colorchange 20s infinite alternate;
  margin-bottom: 38px;
}
li.lastnav {
  margin-top: 15px;
  list-style: none;
}
li.lastnav a {
  background: #3eafb7;
  border: 1px solid #3eafb7;
  color: #fff;
  padding: 13px 22px;
  text-transform: uppercase;
}
li.lastnav a:hover{
  background: #1b5355;
  border: 1px solid #1b5355;
  color: #fff;
  padding: 13px 22px;
  text-transform: uppercase;
}

.abnr {
  padding-top: 84px;
}
span.ready a {
  text-transform: capitalize;
  color: #6fd6de;
}
span.dd {
  margin-right: 3px;
}
span.dd {
  margin-right: 8px;
}
li.header-btn {
  text-transform: uppercase;
  background: #3eafb7;
  color: #fff;
  padding: 13px 22px;
  border: 1px solid #3eafb7;
}
.topbar {
  border-bottom: 1px solid #bcbdc0;
  padding: 7px;
  height: 43px;
}
ul.Enterprise li:nth-child(1){
  padding: 0px 20px 0px 0px;
}
.dfgfg p {
  margin: 0 auto;
  text-align: center;
  color: #fff !important;
}
.dfgfg {
  margin: 0 auto;
  text-align: center;
  padding: 10px 0px 0px 0px;
}
.topbar1 {
  background: #1b5355;
  margin-top: -7px;
}
ul.Enterprise li {
  display: inline-block;
  border-right: 1px solid #bcbdc0;
  padding: 0px 20px;
  font-size: 14px;
  text-align: center;
  color: #000;
}
img.topcall {
  width: 25px;
  margin-right: 8px;
}
ul.Enterp li:nth-child(2){
  padding: 0px 0px 0px 20px;
  border-right: 0px;
}
ul.Enterp i.fa.fa-phone {
  rotate: 133deg;
  color: #3eafb7;
}
ul.Enterp li{
  display: inline-block;
  border-right: 1px solid #bcbdc0;
  padding: 0px 20px;
  font-size: 14px;
  text-align: center;
  color: #000;
}
div#header-row {
  margin-top: 10px;
  margin-bottom: 10px;
}
a.truetop {
  color: #000;
}
ul.Enterp {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  float: right;
  list-style: none;
}
ul.Enterprise {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  float: left;
  padding-left: 0px;

}
.topbar {
  border-bottom: 1px solid #bcbdc0;
}
ul.collect {
  padding-left: 15px;
}
section.policies h2 {
  font-size: 40px;
  margin-bottom: 30px;
}
section.policies {
  padding-top: 80px;
  padding-bottom: 80px;
}
section.Privacy-pilicy {
  padding-top: 100px;
  padding-bottom: 100px;
  background: #255454;
  margin-top: 170px;
  text-align: center;
  position: relative;
}

img.trans2 {
  width: 80%;
  margin: 0 auto;
  display: block;
}
img.trans {
  width: 85%;
  margin: 0 auto;
  text-align: center;
  display: block;
}
h1.dgjhj {
  text-transform: capitalize;
  font-size: 48px;
  line-height: 62px;
  margin-bottom: 30px;
  color: #000;
  font-weight: 800;
  letter-spacing: -1px;
}
.acent {
  padding-top: 40px;
}
img.gh {
  padding-left: 40px;
}
section.obinner {
  position: relative;
  background-image: linear-gradient(180deg, #FFFFFF 0%, #E0ECF0 100%);
  padding-top: 230px;
}
p.bgh {
  background: #53b2b5;
  width: 31%;
  padding: 13px;
  text-align: center;
  border-radius: 5px;
}
img.telephone {
  width: 24px;
}
p.bgh a {
  color: #fff;
  font-size: 17px;
  line-height: 26px;
}
a.Calling {
  color: #000;
}
img.complain {
  margin-bottom: 20px;
}
.conatctcontent {
  padding-top: 120px;
}
img.gentlman0 {
  width: 85%;
  margin: 0 auto;
  display: block;
  border-radius: 5px;
}
div#fddf {
  margin-top: 80px;
}
.cont-box {
  height: 230px;
  padding: 16px 10px;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: center;
  border-radius: 0.25rem;
}
a.Calling {
  font-size: 16px;
}
.cont-form ul .form-control {
  display: block;
  width: 100%;
  padding: 12px;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #212529;}

  section.contactdetail {
    padding-top: 80px;
    padding-bottom: 80px;
}
.cont-form ul {
  list-style: none;
  padding-left: 0px;
}
.cont-form input.form-rol {
  margin-top: 15px;
  user-select: none;
  background: #3eafb7 none repeat scroll 0 0;
  border: medium none;
  border-radius: 4px;
  color: var(--tg-white);
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1;
  margin-bottom: 0px;
  padding: 20px 40px;
  text-align: center;
  text-transform: capitalize;
  touch-action: manipulation;
  transition: all 700ms ease 0s;

}
.cont-form {
  padding: 50px 30px;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 80%;
  margin: 0 auto;
}
.cont-form ul textarea#message {
  width: 100%;
  height: 110px;
  padding: 12px;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.conatctcontent h1 {
  text-transform: capitalize;
  font-size: 48px;
  margin-bottom: 30px;
  color: #000;
  font-weight: 800;
  letter-spacing: -1px;
}
section.contactbnr {
  position: relative;
  background-image: linear-gradient(180deg, #FFFFFF 0%, #E0ECF0 100%);
  padding-top: 220px;
}
.conatctcontent p {
  font-size: 22px;
  line-height: 32px;
  color: #000;
  margin-top: 30px;
  margin-bottom: 30px;
}
section.heromobile {
  background: #000;
  margin-top: 10px;
  padding: 50px 0px;
}
ul.faqlist {
  margin-top: 15px;
}
p.Get {
  margin: 0 auto;
  text-align: center;
  width: 60%;
  margin-top: 24px;
  margin-bottom: 20px;
}
section.homecta a.btn.wow.fadeInUp {
  margin-top: 30px;
}
p.Ehires {
    padding-top: 16px;
    margin-bottom: 70px;
    width: 65%;
    margin: 0 auto;
    padding-bottom: 102px;
}
ul.inl li {
    font-size: 20px;
    padding-right: 10px;
    color: #000;
}
ul.inl {
    padding-left: 0px;
    margin: 0 auto;
    display: ruby-text;
    text-align: center;
    float: none;
    width: 100%;
    margin-top: 50px;
    list-style: none;
}
img.headphone1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: -1;
    opacity: 0.05;
    width: 32%;
}
.abnr p {
    font-size: 22px;
    line-height: 32px;
    width: 70%;
    margin: 0 auto;
    text-align: center;
    margin-top: 45px;
    border-bottom: 2px solid;
    padding-bottom: 30px;
}
section.aboutbner {
    padding-top: 150px;
    padding-bottom: 150px;
    position: relative;
}
.awesome {
    font-family: futura;
    font-style: italic;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    color: #313131;
    font-size: 125px;
    font-weight: bold;
    animation: colorchange 20s infinite alternate;
  }
  
  @keyframes colorchange {
    0% {
      color: #106b6e;
    }
    10% {
      color: #174647;
    }
    20% {
      color: #1a838b;
    }
    30% {
      color: #106b6e;
    }
    40% {
      color: #174647;
    }
    50% {
      color: #106b6e;
    }
    60% {
      color: #1a838b;
    }
    70% {
      color: #079196;
    }
    80% {
      color: #106b6e;
    }
    90% {
      color: #174647;
    }
    100% {
      color: #05777b;
    }
  }

img.target {
    margin-bottom: 30px;
}
div#Emphires {
    margin-top: 40px;
}
.investment_support .wrapper {
    align-items: stretch;
    counter-reset: section;
  }
  .investment_support .wrapper .item .item_wrap .head:before {
    position: relative;
    top: 3px;
    left: 0;
    counter-increment: section;
    content: counter(section, decimal-leading-zero) "/";
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    width: 0px;
    overflow: hidden;
    transition: all 0.4s ease-in-out;
  }
  .investment_support .wrapper .item .item_wrap .head {
    padding-left: 0;
    display: flex;
    align-items: self-start;
    justify-content: flex-start;
  }
  
  .investment_support .wrapper .item {
    text-align: left;
    padding: 0 20px;
    position: relative;
  }
  .investment_support .head h4 {
   color: #fff;
   font-size: 32px;
    margin: 0 0 30px;
    position: absolute;
    transform: translate3d(0px, 0px, 0px);
    transition: all 0.4s ease-in-out;
  }
  
  .investment_support .wrapper .item:after {
    content: "";
    width: 0%;
    height: 1px;
    background: #3eafb7;
    position: absolute;
    display: flex;
    transition: all 0.4s ease-in-out;
  }
  .investment_support .wrapper .item:nth-child(1):after {
    left: auto;
    right: 0;
    top: 55%;
    transform: translate(50%, 0%) rotate(90deg);
  }
  .investment_support .wrapper .item:nth-child(2):after {
    left: 0;
    right: auto;
    top: 100%;
    transform: translate(0%, 0%) rotate(0deg);
  }
  .investment_support .wrapper .item:nth-child(3):after {
    left: auto;
    right: -2px;
    top: 0%;
    transform: translate(0%, 0%) rotate(0deg);
  }
  .investment_support .wrapper .item:nth-child(4):after {
    left: 0px;
    top: 45%;
    transform: translate(-50%, 0%) rotate(90deg);
    right: auto;
    background: #3eafb7;
  }
  
  .investment_support .wrapper .item .text p {
     color: #ffffff;
  }
  .investment_support .wrapper .item .item_wrap {
    padding: 40px;
    margin: 15px 0;
    transition: all 0.4s ease-in-out;
    backdrop-filter: blur(50px);
    width: 100%;
    height: calc(100% - 30px);
  }
  /* .investment_support .wrapper.active .item .item_wrap {
      
          
  } */
  
  .investment_support .wrapper .item .item_wrap:before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /* background: linear-gradient(136.82deg, #1b535563 25.74%, rgb(0 0 0 / 0%) 126.62%); */
    clip-path: polygon(4% 0, 100% 0, 100% 100%, 0 100%, 0 7%);
    opacity: 0;
  }
  .investment_support .wrapper.active .item .item_wrap:before {
    opacity: 1;
    transition-delay: 2s;
  }
  
  .investment_support .wrapper.active .item:after {
    width: 50%;
  }
  
  .investment_support .wrapper.active .item .item_wrap h4 {
    transition-delay: 1s;
  }
  
  .investment_support .wrapper .item:nth-child(1) .head h4 {
    top: calc(100% - 60px);
    left: calc(100% - 160px);
    /* transition: all 0.4s ease-in-out; */
  }
  .investment_support .wrapper .item:nth-child(2) .head h4 {
    transform: translate3d(0%, 0%, 0px);
    top: calc(100% - 60px);
    left: 40px;
    /* transition:  all 0.4s ease-in-out; */
  }
  .investment_support .wrapper .item:nth-child(3) .head h4 {
    left: calc(100% - 160px);
  }
  
  .investment_support .wrapper .item .item_wrap .text {
    opacity: 0;
    transition: all 0.5s ease-in-out;
    margin-top: 35px;
  }
  .investment_support .wrapper.active .item_wrap .text {
    opacity: 1;
    transition-delay: 2.3s;
  }
  
  .investment_support .wrapper.active .item:nth-child(3) .head h4 {
    left: calc(0% - -40px);
  }
  .investment_support .wrapper.active .item:nth-child(2) .head h4 {
    top: calc(0% - -40px);
  }
  .investment_support .wrapper.active .item:nth-child(1) .head h4 {
    top: calc(0% - -40px);
    left: calc(0% - -40px);
  }
  
  .investment_support .wrapper.active .item .head:before {
    width: 40px;
    transition-delay: 2.4s;
  }
  .investment_support .wrapper .item .head h4 span {
    margin-left: 0px;
    transition: all 0.5s ease-in-out;
  }
  .investment_support .wrapper.active .item .head h4 span {
    margin-left: 50px;
    transition-delay: 2.4s;
  }
  
  .investment_support .wrapper.active .item:nth-child(4) .head h4 {
    transition-delay: 4s;
  }
  .investment_support .wrapper.active .item:nth-child(3) .head h4 {
    transition-delay: 3s;
  }
  .investment_support .wrapper.active .item:nth-child(2) .head h4 {
    transition-delay: 2s;
  }
  .investment_support .wrapper.active .item:nth-child(1) .head h4 {
    transition-delay: 1s;
  }
  .investment_support .wrapper.active .item:nth-child(4) .head:before {
    transition-delay: 4.6s;
  }
  .investment_support .wrapper.active .item:nth-child(3) .head:before {
    transition-delay: 3.6s;
  }
  .investment_support .wrapper.active .item:nth-child(2) .head:before {
    transition-delay: 2.6s;
  }
  .investment_support .wrapper.active .item:nth-child(1) .head:before {
    transition-delay: 1.6s;
  }
  .investment_support .wrapper.active .item:nth-child(4) .head h4 span {
    transition-delay: 4.6s;
  }
  .investment_support .wrapper.active .item:nth-child(3) .head h4 span {
    transition-delay: 3.6s;
  }
  .investment_support .wrapper.active .item:nth-child(2) .head h4 span {
    transition-delay: 2.6s;
  }
  .investment_support .wrapper.active .item:nth-child(1) .head h4 span {
    transition-delay: 1.6s;
  }
  .investment_support .wrapper.active .item:nth-child(4) .head img {
    transition-delay: 1.5s;
    transform: translateX(0px);
    opacity: 1;
  }
  .investment_support .wrapper.active .item:nth-child(3) .head img {
    transition-delay: 1.5s;
    transform: translateX(0px);
    opacity: 1;
  }
  .investment_support .wrapper.active .item:nth-child(2) .head img {
    transition-delay: 1.5s;
    transform: translateX(0px);
    opacity: 1;
  }
  .investment_support .wrapper.active .item:nth-child(1) .head img {
    transition-delay: 1.5s;
    transform: translateX(0px);
    opacity: 1;
  }
  
  .investment_support .wrapper.active .item:nth-child(4) .text {
    transition-delay: 5.7s;
  }
  .investment_support .wrapper.active .item:nth-child(3) .text {
    transition-delay: 4.7s;
  }
  .investment_support .wrapper.active .item:nth-child(2) .text {
    transition-delay: 3.7s;
  }
  .investment_support .wrapper.active .item:nth-child(1) .text {
    transition-delay: 2.7s;
  }
  .investment_support .wrapper.active .item:nth-child(4) .item_wrap:before {
    transition-delay: 5.4s;
    transform: rotate(180deg);
  }
  .investment_support .wrapper.active .item:nth-child(3) .item_wrap:before {
    transition-delay: 4.4s;
    transform: rotateX(180deg);
  }
  .investment_support .wrapper.active .item:nth-child(2) .item_wrap:before {
    transition-delay: 3.4s;
    transform: rotateY(190deg);
  }
  .investment_support .wrapper.active .item:nth-child(1) .item_wrap:before {
    transition-delay: 2.4s;
  }
  .investment_support .wrapper {
    position: relative;
  }
  .investment_support .wrapper:before {
    content: "";
    background: #3eafb7;
    width: 40px;
    height: 40px;
    position: absolute;
    border-radius: 60%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    top: 51%;
    z-index: 2;
    border: 12px solid #000;
    transition: all 0.4s ease-in-out;
  }
  .investment_support .wrapper.active:before {
    transform: translate(-50%, -50%) scale(1);
  }
  section.investment_support {
    background: #000;
  }
.icon_box_all.style_eight {
    padding: 30px 30px 10px 30px;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: rgba(9, 0, 0, 0.0509803922);
    border-radius: 30px 30px 30px 30px;
}
.icon_box_all.style_eight .icon_content {
    display: flex;
}
.icon_box_all.style_eight .icon_content .icon {
    min-width: 60px;
    padding-top: 5px;
}
.icon_box_all.style_eight .icon_content .icon span {
    font-size: 40px;
    line-height: 40px;
}
.icon_box_all.style_eight .icon_content .text_box {
    min-height: 80%;
}
.icon_box_all.style_eight .icon_content .text_box h2 {
    line-height: normal;
}
.icon_box_all.style_eight .icon_content .text_box h2 a {
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 2px;
    display: block;
}
.reprehenderit {
    text-align: center;
}
img.process1 {
width: 72%;
    margin-bottom: 20px;
    text-align: center;
}
section.work {
    padding-top: 80px;
    padding-bottom: 80px;
    background: #f9f9f9 none repeat scroll 0 0;
}
p.containing {
    width: 80%;
    margin: 0 auto;
    font-size: 22px;
    line-height: 32px;
    font-weight: 300;
    margin-top: 20px;
    margin-bottom: 40px;
}
section.easier {
    padding-bottom: 80px;
}
.typting {
    padding-left: 40px;
}
.sfs .react-tabs__tab--selected {
    border-color: #aaa;
    border-radius: 5px 5px 0 0;
    background: #53b2b5 !important;
    color: #fff !important;
    padding: 13px 35px;
    margin-bottom: 10px;
    font-size: 20px;
    text-transform: capitalize;
}
.Distrution .bnrcta {
    margin-top: 55px;
    text-align: left;
}
.leding {
    margin-top: 40px;
    margin-bottom: 30px;
    border-top: 2px solid;
    padding-top: 38px;
    /* padding: 30px 30px 10px 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 40px 0px rgba(54,54,54,0.2); */
}
.Distrution {
    text-align: left;
    margin-top: 50px;
}
div#tures {
    margin-top: 36px;
}
.sfs .react-tabs__tab {
    font-size: 20px;
    font-weight: 600;
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: 0;
    color: #000;
    position: relative;
    list-style: none;
    padding: 13px 35px;
    cursor: pointer;
    margin-bottom: 10px;
    background: #f1f0ef;
    text-transform: capitalize;
}
.sfs {
    margin-top: 40px;
}
section.ibfeatures {
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
}
section.whyib {
    padding-bottom: 70px;
    padding-top: 20px;
}

div#scalability {
    margin-top: 60px;
}
img.icee {
    width: 44px;
    margin-right: 20px;
}
.voice {
    margin-bottom: 30px;
    padding: 30px 30px 10px 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 40px 0px rgba(54,54,54,0.2);
}
.led h4:after {
    background-color: #3eafb7;
    border-radius: 0;
    content: "";
    display: inline-block;
    height: 3px;
    left: 0;
    margin: 0;
    position: absolute;
    top: -2px;
    vertical-align: bottom;
    width: 50px;
}
.led h4 {
    border-top: 1px solid rgba(0, 0, 0, .15);
    font-weight: 700;
    margin-bottom: 20px;
    padding-top: 30px;
    position: relative;
}
img.inbo {
    margin-bottom: 30px;
}
.led:hover {
    box-shadow: 0px 0px 40px 0px rgba(54,54,54,0.2);
    transition: ease-out 0.2s;
    padding: 30px;
}
.led {
    /* box-shadow: 0px 0px 40px 0px rgba(54,54,54,0.2); */
    transition: ease-out 0.2s;
    padding: 30px;
}
section.ib-serivces {
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
}
section.indus-sec .row.no-gutters.m-0 div#Unknown {
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    padding: 0;
}
img.inboundbnr {
    position: absolute;
    bottom: 0;
    right: 10%;
}
p.colyright {
    color: #fff !important;
    opacity: 1 !important;
    text-align: center;
}
p.Designing {
    color: #fff;
    margin-top: 20px;
    margin-bottom: 30px;
}
.footer-title h4 {
    color: #3eafb7;
}
div#footer03 {
    border-top: 1px solid #cfcfcf;
    padding-top: 40px;
    margin-top: 40px;
}
div#containing {
    border-bottom: 1px solid;
    padding-bottom: 30px;
}
img.orange {
    margin-bottom: 40px;
}
    
    
img.orange1 {
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-bottom: 40px;
}
section.oufrea .bnrcta {
    float: right;
}
.who p {
    font-size: 22px;
    line-height: 32px;
    margin-top: 25px;
}
img.x1 {
    margin-bottom: 20px;
    width: 50%;
}
section.signlegirl {
    background: #f9f9f9 none repeat scroll 0 0;
}
.who {
    margin-right: 30px;
}
.Professionals p {
    padding-top: 25px;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
}
section.oufrea {
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
    background-image: linear-gradient(180deg, #FFFFFF 0%, #E0ECF0 100%);
}
span.lightspan {
    font-weight: 300;
}
.webser {
    margin-top: 40px;
}
.webser form input.form-rol {
  text-transform: uppercase;
  margin-left: 2px;
    color: rgb(255, 255, 255);
    font-weight: 400;
    background: #3eafb7;
    padding: 9px 55px;
    border-width: 1px;
    border-style: solid;
    border-color:#3eafb7;
    border-image: initial;
    overflow: hidden;
    border-radius: 0px;
    text-decoration: none;
    box-shadow: 0 5px 15px 0 rgba(107,124,147,.15), 0 2px 4px 0 rgba(107,124,147,.2), inset 0 -2px 0 0 #c2cfde;
    transition: 0.5s cubic-bezier(.25,.8,.25,1) 0s;
}
.webser form textarea#message {
    width: 220px;
    padding: 10px;
    height: 46px;
    font-size: 15px;
    margin-right: 10px;
    border: 1px solid #18171757 !important;
    box-shadow: 0 5px 15px 0 rgba(107,124,147,.15), 0 2px 4px 0 rgba(107,124,147,.2), inset 0 -2px 0 0 #c2cfde;
    transition: 0.5s cubic-bezier(.25,.8,.25,1) 0s;
}
.webser ul li input.form-control {
    width: 220px;
    height: 46px;
    font-size: 15px;
    margin-right: 10px;
    border: 1px solid #77777757;
    box-shadow: 0 5px 15px 0 rgba(107,124,147,.15), 0 2px 4px 0 rgba(107,124,147,.2), inset 0 -2px 0 0 #c2cfde;
    transition: 0.5s cubic-bezier(.25,.8,.25,1) 0s;
}
.webser ul {
    display: inline-flex;
    list-style: none;
    margin: 0 auto;
}
section.banner-area video {
    object-fit: cover;
    width: 48%;
    border-radius: 50%;
    margin: 0 auto;
    display: block;
    text-align: center;
    opacity: 0.2;
}
span.Masterys {
    font-size: 50px;
    font-weight: 500;
}

#flip {
    height: 82px;
    margin-top: 20px;
    overflow:hidden;
  }
  
  #flip > div > div {
    color:#3eafb7;
    padding:4px 12px;
    height:45px;
    margin-bottom:45px;
    display:inline-block;
  }
  
  #flip div:first-child {
    animation: show 5s linear infinite;
  }
  
  /* #flip div div {
    background:#42c58a;
  }
  #flip div:first-child div {
    background:#4ec7f3;
  }
  #flip div:last-child div {
    background:#DC143C;
  } */
  
  @keyframes show {
    0% {margin-top:-270px;}
    5% {margin-top:-180px;}
    33% {margin-top:-180px;}
    38% {margin-top:-90px;}
    66% {margin-top:-90px;}
    71% {margin-top:0px;}
    99.99% {margin-top:0px;}
    100% {margin-top:-270px;}
  }
  
.banner-content {
    position: absolute;
    top: 55%;
    /* top: 50%; */
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: 1;
}

img.girl {
    margin: 0 auto;
    text-align: center;
    width: 28%;
    display: block;
    display: block;
    margin-bottom: -22px;
}
img.morph {
    position: absolute;
    left: 12%;
    bottom: 0;
    z-index: -1;
}
img.pick5 {
    position: absolute;
    width: 5%;
    left: 22%;
    bottom: 3%;
    opacity: 0.1;
}

img.pick6 {
    position: absolute;
    width: 5%;
    right: 22%;
    bottom: 3%;
    opacity: 0.1;
}
img.pick4 {
    position: absolute;
    width: 6%;
    right: 4%;
    top: 7%;
    opacity: 0.1;
}
img.pick1 {
    position: absolute;
    width: 6%;
    left: 4%;
    top: 24%;
    opacity: 0.2;
}
img.pick3 {
    position: absolute;
    left: 16%;
    top: 56%;
    opacity: 0.1;
}
img.pick2 {
    position: absolute;
    width: 6%;
    right: 6%;
    top: 45%;
    opacity: 0.1;
}
.hero-banner {
    margin: 0 auto;
    text-align: center;
}
.hero-banner .typed-cursor{
    display: none;
}
.iam {
    color: #000;
    font-size: 66px;
    font-weight: 700;
    padding: 15px;
    letter-spacing: -1px;
    line-height: 82px;
    margin-bottom: 25px;
}
  .text{
    font-weight: 700;
      /* border-right: 2px solid #3eafb7; */
      font-size: 55px;
      color: #3eafb7;
      margin-bottom: 44px;
  }
  .bnrcta a {
    text-transform: uppercase;
    background: #1b5355;
    color: #fff;
    padding: 18px 22px;
}
.bnrcta a:hover{
  text-transform: uppercase;
  background: transparent;
  color: #fff;
  padding: 18px 22px;
  background: #3eafb7;

}
.bnrcta {
    margin-top: 55px;
    text-align: center;}
    p.Been {
        text-align: center;
        font-size: 20px;
        line-height: 35px;
       font-weight: 300;
        margin-top: 35px;
        width: 60%;
        margin: 0 auto;
    }

.rotatingText-content { 
    position: relative;
    width: 100%;
  }
  
  .rotatingText-description { 
    font-family: 'Georgia', serif;
    font-style: italic;
    font-size: 14px;
    margin: 0;
    
    @media (min-width: 768px) { 
      font-size: 18px;
    }
  }
  
  .rotatingText-adjective { 
    font-weight: 700;
    font-size: 55px;
    margin: 0;
    color: #3eafb7;
    left: 0;
    margin-bottom: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: center;
    text-transform: capitalize;
    top: 0px;
    
   
    &:nth-of-type(1) { 
      animation: rotate-text-up 1.5s .75s; 
    }
    
    &:nth-of-type(2) { 
      animation: rotate-text-up 1.5s 2s; 
    }  
    
    &:nth-of-type(3) { 
      animation: fade-text-in 1.5s 3.25s forwards;
    }
  
  }
  
  
  @keyframes rotate-text-up { 
    0% {
      transform: translate3d(0, 80px, 0);
      opacity: 0;
    }
    
    20%, 80% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    
    100% {
      transform: translate3d(0, -40px, 0);
      opacity: 0;
    }
  }
  
  @keyframes fade-text-in { 
    0% {
      opacity: 0;
      transform: translate3d(0, 80px, 0);
    }
    
    50%, 100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  
.Emphires {
    margin-bottom: 40px;
    text-align: center;
}
.footer-area-two.footer-area-three {
    background: #000;
}
img.bannergirl22 {
    width: 69%;
    margin: 0 auto;
    display: block;
    text-align: center;
}
.accordion-item {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125);
    border-left: 0px !important;
    border-right: 0px;
}
.green-underline {
    width: 83px;
    height: 2.6px;
    background-color: #1b5355;
    margin-top: -2.5px;
    margin-bottom: 26px;
    margin-left: 173px;
}

.grey-line {
    width: 276px;
    height: 1px;
    border: 1px solid rgb(185 185 185 / 20%);
    margin-top: 22px !important;
    margin: 0 auto;

}
section.workpr {
padding-top: 100px;
padding-bottom: 60px;
}
img.inner1pick {
    width: 42%;
    position: absolute;
    bottom: -38px;
    right: 8%;
}
p.custmer1 {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 40px;
    color: #000;
}
section.inner1 {
    position: relative;
    background-image: linear-gradient(180deg, #FFFFFF 0%, #E0ECF0 100%);
    padding-top: 130px;
    padding-bottom: 100px;
}
.brow p {
    width: 58%;
    margin: 0 auto;
    font-size: 18px;
    color: #ffffff;
    line-height: 28px;
    border-top: 1px solid;
    padding-top: 20px;
    padding-bottom: 40px;
}
h2.encompass {
    color: #fff;
}
.brow h3 {
    font-weight: 400;
    color: #fff;
    margin-bottom: 30px;
    font-size: 26px;
    line-height: 36px;
}
section.in-sec-1 {
    padding-top: 50px;
    background: #1b5355;
    padding-bottom: 50px;
    text-align: center;
    position: relative;
}
ul.in-ul li {
    font-size: 18px;
    padding-right: 10px;
    color: #000;
}
ul.in-ul {
    padding-left: 0px;
    list-style: none;
    display: flex;
    margin-bottom: 40px;
}
.acontent {
    padding-top: 70px;
}
img.inboundbnr {
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 32%;
}
h1.dg {
    text-transform: capitalize;
    font-size: 48px;
    line-height: 62px;
    margin-bottom: 30px;
    color: #000;
    font-weight: 800;
    letter-spacing: -1px;
}
section.rule h2 {
    margin-bottom: 25px;
}
.unknown {
    margin-top: 40px;
}
ul.when li {
    margin-bottom: 10px;
}
section.rule {
    padding-top: 150px;
    padding-bottom: 100px;
}
ul.when {
    padding-left: 0;
    list-style: none;
    margin-top: 30px;
}
img.signle {
    border-radius: 10px;
}
section.abtus hr {
    margin-top: -95px;
    z-index: -9999;
    height: 130px !important;
    position: relative;
    top: 19%;
    border-bottom: 1px solid #3eafb7;
}
section.abt-dtail ul.react-tabs__tab-list {
    margin-right: 50px;
}
section.abtus p.custmer {
    font-size: 20px;
    margin-bottom: 50px;
}
section.abtus {
    position: relative;
    background-image: linear-gradient(8deg, #dbdadd 12%, #fff6e7 100%);
    /* background-image: linear-gradient(8deg, #e2dddd 56%, #fff6e7 100%); */
}
.Ipsum {
    margin-top: 36px;
}
.react-tabs__tab {
    font-size: 22px;
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: 0;
    position: relative;
    list-style: none;
    padding: 20px 12px;
    cursor: pointer;
    margin-bottom: 10px;
    background: #f1f0ef;
}
.react-tabs__tab--selected {
    background: #fff;
    border-color: #aaa;
    border-radius: 5px 5px 0 0;
    background: #53b2b5;
    color: #fff;
    padding: 20px 12px;
    margin-bottom: 10px;
    font-size: 22px;
}
img.bnrabt {
    width: 50%;
    margin-bottom: -132px;
}
section.abt-dtail {
    padding-top: 114px;
}
h1.partners {
    font-size: 65px;
    text-transform: capitalize;
    margin-bottom: 25px;
}

.abtcontent {
    padding-top: 180px;
    text-align: center;
}
/* img.bnrvideo {
    position: absolute;
    right: 13%;
    top: 20%;
    width: 31%;
} */
.accordion.accordion-flush {
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 50px;
}
.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 18px 10px;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: #212529;}

 .accordion-button:not(.collapsed) {
    color: #2c99a0 !important;
    background-color: #fff  !important;
    box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
    }
    .accordion-collapse.collapse.show{
      font-family: "Montserrat", sans-serif;
      border-top: 1px solid rgb(0 0 0 / 7%) !important;
      border-bottom: 1px solid rgb(0 0 0 / 7%) !important;

    }
    .accordion-body {
        padding: 30px 10px !important;
        text-align: left;
        font-family: "Montserrat", sans-serif;
    border-top: 1px solid rgb(0 0 0 / 1%)  !important;
    border-bottom: 1px solid rgb(0 0 0 / 1%);
    }
.accordion-button:focus {
    z-index: 3;
    border-color: #3eafb7;
    outline: 0;
    box-shadow: unset;
}
.accordion-body {
    padding: 1rem 1.25rem;
    text-align: left;
}
section.techno {
    padding-top: 80px;
    background: #1b5355;
    padding-bottom: 80px;
}
p.premier {
  width: 60%;
  margin: 0 auto;
  margin-top: 24px;
}

div#assisting {
    padding-top: 60px;
}
ul.bnerlist {
    list-style: none;
    padding-left: 0px;
    margin-bottom: 35px;
}
.appoint h4 {
    margin-bottom: 15px;
}
.appoint {
    background-color: #f9f9f9;
    border-radius: 16px;
    padding: 25px;
    margin-bottom: 10px;
}
img.gentlman {
    border-radius: 50px;
    padding-right: 30px;
}
ul.bnerlist li {
    font-size: 20px;
    line-height: 44px;
    color: #000;
}
img.star {
    margin-right: 10px;
}
p.custmer {
    font-size: 20px;
}
div#cta1:before {
    background-image: url(../img/shadow1.png);
    content: "";
    bottom: -44px;
    background-size: cover;
    height: 44px;
    width: 360px;
    position: absolute;
    right: 0;
}
div#cta1:after {
    background-image: url(../img/shadow2.png);
    content: "";
    bottom: -44px;
    background-size: cover;
    height: 44px;
    width: 360px;
    position: absolute;
    left: 0;
}
p.custr {
    margin-bottom: 25px;
    margin-top: 10px;
}
section.homecta {
    padding-top: 70px;
    padding-bottom: 80px;
    position: relative;
    background: #f9f9f9 none repeat scroll 0 0;
}
.indus-sec .indus-box:hover {
    background: #fff;
}
.meaningful h3 {
    font-size: 32px;
    line-height: 42px;
}
section.indus-sec {
    background: #f9f9f9 none repeat scroll 0 0;
}
div#cta1 {
    /* background: #f9f9f9 none repeat scroll 0 0; */
    /* border-left: 7px solid #3eafb7;
    border-right: 7px solid #3eafb7; */
    border-bottom: 1px solid #dadada;
    padding: 20px 30px;
    text-align: center;
    position: relative;
    z-index: 1;
    font-size: 20px;
    line-height: 38px;
    border-top: 1px solid #dadada;
    margin: 0 auto;
    background: #fff;
    width: 80%;
}

.accordion {
    margin-top: 45px;
}
.meaningful {
    text-align: left;
    padding-top: 30px;
 
}
section.stsret {
    padding-top: 80px;
    padding-bottom: 100px;
}
.streamline {
    margin-bottom: 48px;
    border-bottom: 1px solid;
    padding-bottom: 20px;
}

section.propsition {
    padding-bottom: 80px;
    padding-top: 80px;
}
span.green1 {
    color: #3eafb7;
}
.indus-box p {
    margin-top: 15px;

}
img.inbound1 {
    margin-bottom: 20px;
}
video.bnrvideo {
    position: absolute;
    top: 18%;
    right: 10%;
}
.indus-sec .indus-box {
    position: relative;
    z-index: 2;
    transition: .3s all ease-in-out;
    text-align: center;
    border-bottom: 3px solid transparent;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.indus-sec .indus-box i {
    margin: auto;
    margin-bottom: 35px;
}
.indus-sec .indus-box h5 {
    font-size: 24px;
}
.indus-sec .row-outer::before {
    content: "";
    height: 100%;
    width: 1px;
    background-color: #fff;
    position: absolute;
    right: 0%;
    top: 0;
    z-index: 1;
}
.indus-sec .row-outer::before {
    content: "";
    height: 100%;
    width: 1px;
    background-color: #fff;
    position: absolute;
    right: 0%;
    top: 0;
    z-index: 1;
}

@media (min-width: 1280px){
.indus-sec .indus-box {
    padding: 40px 20px;
}
}
.indus-sec .row .col-md {
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    padding: 0;
}
.indus-sec .indus-box:hover {
    border-bottom-color: #1b5355;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
}
.indus-sec .row-outer::after {
    content: "";
    height: 1px;
    width: 100%;
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
}
.indus-sec .indus-box h5 {
    margin-top: auto;
      margin-bottom: 0;
}
img.bootomarrow {
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    left: 50%;
    bottom: -6px;
    z-index: 2;
    display: inline-block;
}
h4.subheading {
    color: #3eafb7;
    font-weight: 400;
    font-size: 18px;
    text-transform: uppercase;
}
img.bannergirl {
    position: absolute;
     top: 20%;
    width: 30%;
    right: 107px;
}
section.banner-bottom {
    background: #255454;
    padding: 60px 0px 80px 0px;
    text-align: center;
    position: relative;
}
section.banner-bottom h2 {
    color: #fff;
    font-size: 45px;
    text-transform: capitalize;
    line-height: 62px;
}
/* .banner-area .info-list ul li:nth-child(1) {
      right: 25%;
    position: absolute;
}
.banner-area .info-list ul li:nth-child(2) {
    right: 25%;
  position: absolute;

}
.banner-area .info-list ul li:nth-child(3) {
    right: 25%;
  position: absolute;

}

.banner-area .info-list ul li:nth-child(4) {
    right: 25%;
  position: absolute;

} */

.custom-border {
    position: relative;
  }
  
  .custom-border:after {
    content: " ";
    position: absolute;
    border-left: 1px #6c757d solid;
    top: 35%;
    right: 0;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
  }

.info-list li {
    list-style: none;
    width: 256px;
    padding: 10px;
    text-align: center;
    line-height: 50px;
    font-weight: 500;
    font-size: 18px;
    background-color: #ffffff;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}
img.headphones {
    margin-right: 10px;
}
.info-list {
    position: absolute;
    right: 26%;
    margin-top: -80px;
}

.banner-content .title {
font-size: 80px;
margin-bottom: 30px;
line-height: 80px;
color: #000;
text-align: center;
font-weight: 800;
letter-spacing: -1px;
text-shadow: 0px 5px 5px rgba(0,0,0,.25);
}

.customer {
  font-size: 24px;
   font-weight: 200;
    margin-bottom: 35px;
    line-height: 40px;
    }

    @media (max-width: 374px) and (min-width: 320px){
      h4 {
        font-size: 20px;
        line-height: 30px;
        }
      .list-wrap {
        margin-bottom: 10px;
    }
      section.ib-serivces {
        padding-top: 10px;
    }
      .indus-sec .indus-box {
        padding: 20px 0px;}

      .thankyoubox h3 {
        font-size: 22px;
        line-height: 32px;
        font-weight: 700;
        text-transform: capitalize;
    }
      img.thankyou {
        width: 42%;
        margin-bottom: 30px;
    }
      section.thankyou {
        padding-top: 150px;
        padding-bottom: 50px;
    }
      .banner-content .awesome {
           font-size: 36px;
           margin-bottom: 30px;
    }
      section.contactbnr {
          padding-top: 130px;
           }
      .abnr {
        padding-top: 0;
    }
      div#header-row {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .topbar1 {
      display: none;
  }
  .topbar {
    display: none;
}
      section.Privacy-pilicy {
           margin-top: 90px;
    }
      section.policies h2 {
        font-size: 32px;
        margin-bottom: 30px;
        text-align: center;
    }
      section.policies {
        padding-top: 50px;
        padding-bottom: 50px;
    }
      section.obinner {
        padding-top: 70px;
    }
    h1.dgjhj {
      text-transform: capitalize;
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 15px;}

    .acent {
      text-align: center;
  }
  img.gh {
    padding-left: 0px;
    margin-top: 40px;
}
      .conatctcontent {
        padding-top: 0px;
        text-align: center;
    }
    .conatctcontent h1 {
      text-transform: capitalize;
      font-size: 36px;
      line-height: 46px;
      margin-bottom: 0;}

      .conatctcontent p {
        font-size: 16px;
        line-height: 26px;
        color: #000;
        margin-top: 16px;
        margin-bottom: 30px;
    }
    .cont-form {
      padding: 30px 15px 20px 15px;
      margin-top: 30px;
      width: 100%;
  }
  div#fddf {
    margin-top: 30px;
}
.cont-box {
  height: unset;
  margin-bottom: 20px;}

  .cont-form ul .form-control {
    display: block;
    width: 100%;
    padding: 12px;
    margin-bottom: 8px;
    line-height: 20px;
    color: #212529;
}
    img.contctbnr {
      margin-top: 30px;
  }
  section.contactdetail {
    padding-top: 50px;
    padding-bottom: 50px;
}





      .acontent {
        padding-top: 0px;
        text-align: center;
    }
    p.custmer1 {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 10px;
      color: #000;
  }
  ul.in-ul li {
    font-size: 16px;
    line-height: 32px;
    padding-right: 10px;
    color: #000;
}
  ul.in-ul {
    padding-left: 0px;
    list-style: none;
    display: block;
    margin-bottom: 25px;
}
section.inner1 {
  padding-bottom: 60px;
}
.led:hover {
  padding: 30px 10px;
}
.led{
  padding: 30px 10px;
  text-align: center;
}
.led h4:after {
  left: 40%;
 }
 section.ib-serivces {
  padding-bottom: 0px;
}
div#scalability {
  margin-top: 35px;
}
img.inboundbnr {
  display: none;}

 h1.dg {
  text-transform: capitalize;
  font-size: 35px;
  margin-bottom: 20px;}
  .rightnew h4 {
    display: grid;
}
img.icee {
  width: 44px;
  margin-right: 0;
  margin-bottom: 20px;
}
img.bann {
  margin-bottom: 30px;
}
section.whyib {
  padding-bottom: 30px;
}
section.ibfeatures {
  position: relative;
  padding-top: 60px;
  padding-bottom: 30px;
}
.sfs .react-tabs__tab {
  font-size: 16px;
  padding: 12px 30px;
  display: block;
}
.Distrution {
  text-align: center;
  margin-top: 40px;
}
.Distrution .bnrcta {
  margin-top: 30px;
  text-align: center;
}
.voice {
  text-align: center;
  margin-bottom: 30px;
  padding: 30px 15px 10px 15px;}


 /* internal page      */
      .row {
        --bs-gutter-x: 0;
    }
    .herobox {
      text-align: center;
  }
  p.Ehires {
    padding-top: 16px;
    margin-bottom: 70px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 50px;
}
  section.work {
    padding-top: 50px;
    padding-bottom: 50px;
    background: #f9f9f9 none repeat scroll 0 0;
}
      section.investment_support {
        display: none;
    }
      span.Saving1 {
        font-size: 26px;
        color: #3eafb7;
    }
      img.morph {
        position: absolute;
        left: 0px;
        bottom: 0;
        z-index: -1;
    }
    .awesome {
      font-size: 60px;
  
    }
    .abnr p {
      font-size: 16px;
      line-height: 26px;
       text-align: center;
       width: 100%;
       margin-top: 24px;
  }
  ul.inl li {
    font-size: 16px;
 }
 ul.inl {
  margin-top: 30px;
}
.abnr .bnrcta {
  margin-top: 40px;
}
section.aboutbner {
  padding-top: 120px;
  padding-bottom: 80px;
  position: relative;
}
.typting {
  padding-left: 0px;
  text-align: center;
  margin-top: 30px;
}
ul.when {
  padding-left: 0;
  list-style: none;
  margin-top: 10px;
  text-align: center;
}
p.containing {
  width: 100%;
  margin: 0 auto;
  font-size: 16px;
  line-height: 26px;
  margin-top: 20px;
  margin-bottom: 0px;
}
.brow p {
  width: 100%;
  font-size: 16px;
}
section.rule {
  padding-top: 50px;
  padding-bottom: 40px;
}

section.easier {
  padding-bottom: 10px;
}

/* our company */



      p.Get {
        margin: 0 auto;
        text-align: center;
        width: 100%;
        margin-top: 24px;
        margin-bottom: 0px;
    }
      p.premier {
        width: 100%;
        margin: 0 auto;
        margin-top: 24px;
    }
      p.caring br {
        display: none;
    }
      ul.when li {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 27px;
    }
      img.star {
        margin-right: 4px;
        width: 19px;
    }
        .banner-content .title {
            font-size: 28px !important;
            margin-bottom: 0px;
            line-height: 38px !important;
            }
     div#cta1:after {
         width: 295px;
     }
     .footer-top-two {
        padding: 50px 0 45px !important;
    }
     .meaningful a.btn.wow.fadeInUp {
        margin: 0 auto;
        margin-top: 30px;
        padding: 18px 30px;
        display: block;
        width: 80%;
        margin-bottom: 40px;
    }
     img.orange {
        margin-bottom: 40px;
   
    }
    .accordion-button {
      font-size: 16px;
     line-height: 26px;
     }
  section.stsret {
    padding-top: 50px;
    padding-bottom: 50px;
    }
    .btn {
        padding: 18px 30px;
    }
    p.Get br {
        display: none;
    }
     .who {
        margin-right: 0px;
        text-align: center;
    }
    section.oufrea .bnrcta {
        float: none;
        margin-bottom: 30px;
    }
    .green-underline {
      margin-left: 115px;
    }
    .who p {
        font-size: 16px;
        line-height: 26px;
        margin-top: 20px;
        margin-bottom: 40px;
    }
     section.oufrea {
        padding-top: 50px;
        padding-bottom: 20px;}

     .webser form input.form-rol {
        width: 100%;}
   section.homecta {
     padding-top: 0px;}

            .webser form textarea#message {
             width: 100%;
             padding: 10px;
             height: 80px;
              font-size: 15px;
             margin-right: 0px;
               }
            h2 {
                font-size: 28px;
                text-transform: capitalize;
                line-height: 38px;
            }
            .webser ul li input.form-control {
                width: 100%;
                margin-bottom: 6px;
                height: 46px;
                font-size: 14px;
                margin-right: 0px;}
            section.techno {
                padding-top: 50px;
                background: #1b5355;
                padding-bottom: 50px;
            }
            .webser ul {
                display: block;
                list-style: none;
                margin: 0 auto;
                padding-left: 0px;
            }
            p.premier br {
                display: none;
            }
            h4.subheading {
             font-size: 16px;
            }
            p.custr {
                margin-bottom: 0px;
                margin-top: 16px;
                text-align: center;
            }
            img.girl {
                margin: 0 auto;
                text-align: center;
                width: 100%;
                display: block;
                margin-bottom: -13px;
            }
        .meaningful {
           text-align: center;
            position: unset;
            top: unset;
          width: 100%;
            }
            .meaningful h3 {
                font-size: 24px;
                line-height: 32px;
                text-align: center;
            }
        div#cta1 {
         padding: 20px 15px;
         width: 100%;
         display: flex;
        flex-direction: column-reverse;
         }
            section.banner-bottom h2 {
             font-size: 30px;
             line-height: 40px;
            }
            .bnrcta {
                margin-top: 0px;
                text-align: center;
            }
            .bnrcta a {
              padding: 15px 22px;
              font-size: 14px;
            }
            .banner-bg {
                padding: 110px 0 50px  !important;
            }
        section.banner-area video {
            object-fit: cover;
            width: 70%;
         }
         span.Masterys {
            font-size: 24px;
            font-weight: 500;
        }
        p.Been {
            display: none;
                }
        .banner-content {
            position: absolute;
            top: 55% !important;
            width: 100%;}
    }

   @media (max-width: 425px) and (min-width: 375px){
    h4 {
      font-size: 20px;
      line-height: 30px;
      }
    .indus-sec .indus-box {
      padding: 20px 0px;}
    .thankyoubox h3 {
      font-size: 22px;
      line-height: 32px;
      font-weight: 700;
      text-transform: capitalize;
  }
    img.thankyou {
      width: 42%;
      margin-bottom: 30px;
  }
    section.thankyou {
      padding-top: 150px;
      padding-bottom: 50px;
  }
    .banner-content .awesome {
      font-size: 36px;
      margin-bottom: 30px;
}
    section.contactbnr {
      padding-top: 130px;
       }
    .abnr {
      padding-top: 0;
  }
    div#header-row {
      margin-top: 0px;
      margin-bottom: 0;
  }
  .topbar1 {
    display: none;
}
.topbar {
  display: none;
}
    section.Privacy-pilicy {
      margin-top: 90px;
}
    section.policies h2 {
      font-size: 32px;
      margin-bottom: 30px;
      text-align: center;
  }
    section.policies {
      padding-top: 50px;
      padding-bottom: 50px;
  }
    section.obinner {
      padding-top: 70px;
  }
  h1.dgjhj {
    text-transform: capitalize;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 15px;}

  .acent {
    text-align: center;
}
img.gh {
  padding-left: 0px;
  margin-top: 40px;
}
    .conatctcontent {
      padding-top: 0px;
      text-align: center;
  }
  .conatctcontent h1 {
    text-transform: capitalize;
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 0;}

    .conatctcontent p {
      font-size: 16px;
      line-height: 26px;
      color: #000;
      margin-top: 16px;
      margin-bottom: 30px;
  }
  .cont-form {
    padding: 30px 15px 20px 15px;
    margin-top: 30px;
    width: 100%;
}
div#fddf {
  margin-top: 30px;
}
.cont-box {
height: unset;
margin-bottom: 20px;}

.cont-form ul .form-control {
  display: block;
  width: 100%;
  padding: 12px;
  margin-bottom: 8px;
  line-height: 20px;
  color: #212529;
}
  img.contctbnr {
    margin-top: 30px;
}
section.contactdetail {
  padding-top: 50px;
  padding-bottom: 50px;
}

    .acontent {
      padding-top: 0px;
      text-align: center;
  }
  p.custmer1 {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px;
    color: #000;
}
ul.in-ul li {
  font-size: 16px;
  line-height: 32px;
  padding-right: 10px;
  color: #000;
}
ul.in-ul {
  padding-left: 0px;
  list-style: none;
  display: block;
  margin-bottom: 25px;
}
section.inner1 {
padding-bottom: 60px;
}
.led:hover {
padding: 30px 10px;
}
.led{
padding: 30px 10px;
text-align: center;
}
.led h4:after {
left: 40%;
}
section.ib-serivces {
padding-bottom: 0px;
}
div#scalability {
margin-top: 35px;
}
img.inboundbnr {
display: none;}

h1.dg {
text-transform: capitalize;
font-size: 35px;
margin-bottom: 20px;}
.rightnew h4 {
  display: grid;
}
img.icee {
width: 44px;
margin-right: 0;
margin-bottom: 20px;
}
img.bann {
margin-bottom: 30px;
}
section.whyib {
padding-bottom: 30px;
}
section.ibfeatures {
position: relative;
padding-top: 60px;
padding-bottom: 30px;
}
.sfs .react-tabs__tab {
font-size: 16px;
padding: 12px 30px;
display: block;
}
.Distrution {
text-align: center;
margin-top: 40px;
}
.Distrution .bnrcta {
margin-top: 55px;
text-align: center;
}
.voice {
text-align: center;
margin-bottom: 30px;
padding: 30px 15px 10px 15px;}


/* internal page      */
    .row {
      --bs-gutter-x: 0;
  }
  .herobox {
    text-align: center;
}
p.Ehires {
  padding-top: 16px;
  margin-bottom: 70px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 50px;
}
section.work {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #f9f9f9 none repeat scroll 0 0;
}
    section.investment_support {
      display: none;
  }
    span.Saving1 {
      font-size: 26px;
      color: #3eafb7;
  }
    img.morph {
      position: absolute;
      left: 0px;
      bottom: 0;
      z-index: -1;
  }
  .awesome {
    font-size: 60px;

  }
  .abnr p {
    font-size: 16px;
    line-height: 26px;
     text-align: center;
     width: 100%;
     margin-top: 24px;
}
ul.inl li {
  font-size: 16px;
}
ul.inl {
margin-top: 30px;
}
.abnr .bnrcta {
margin-top: 40px;
}
section.aboutbner {
padding-top: 120px;
padding-bottom: 80px;
position: relative;
}
.typting {
padding-left: 0px;
text-align: center;
margin-top: 30px;
}
ul.when {
padding-left: 0;
list-style: none;
margin-top: 10px;
text-align: center;
}
p.containing {
width: 100%;
margin: 0 auto;
font-size: 16px;
line-height: 26px;
margin-top: 20px;
margin-bottom: 0px;
}
.brow p {
width: 100%;
font-size: 16px;
}
section.rule {
padding-top: 50px;
padding-bottom: 40px;
}

section.easier {
padding-bottom: 10px;
}

/* our company */
    section.investment_support {
      display: none;
  }
    p.Get {
      margin: 0 auto;
      text-align: center;
      width: 100%;
      margin-top: 24px;
      margin-bottom: 0px;
  }
    p.premier {
      width: 100%;
      margin: 0 auto;
      margin-top: 24px;
  }
    p.caring br {
      display: none;
  }
    ul.when li {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 27px;
  }
    img.star {
      margin-right: 4px;
      width: 19px;
  }
        .banner-content .title {
          font-size: 35px !important;
          margin-bottom: 0px;
          line-height: 40px !important;
            }
     div#cta1:after {
         width: 295px;
     }
     .footer-top-two {
        padding: 50px 0 45px !important;
    }
     .meaningful a.btn.wow.fadeInUp {
        margin: 0 auto;
        margin-top: 30px;
        padding: 18px 30px;
        display: block;
        width: 80%;
        margin-bottom: 40px;
    }
     img.orange {
        margin-bottom: 40px;
        margin-top: 0px;
    }
    .accordion-button {
      font-size: 16px;
     line-height: 26px;
     }
  section.stsret {
    padding-top: 50px;
    padding-bottom: 50px;
    }
    .btn {
        padding: 18px 30px;
    }
    p.Get br {
        display: none;
    }
     .who {
        margin-right: 0px;
        text-align: center;
    }
    section.oufrea .bnrcta {
        float: none;
        margin-bottom: 30px;
    }
    .green-underline {
      margin-left: 115px;
    }
    .who p {
        font-size: 16px;
        line-height: 26px;
        margin-top: 20px;
        margin-bottom: 40px;
    }
     section.oufrea {
        padding-top: 50px;
        padding-bottom: 20px;}

     .webser form input.form-rol {
        width: 100%;}
   section.homecta {
     padding-top: 40px;}

            .webser form textarea#message {
             width: 100%;
             padding: 10px;
             height: 80px;
              font-size: 15px;
             margin-right: 0px;
               }
            h2 {
                font-size: 28px;
                text-transform: capitalize;
                line-height: 38px;
            }
            .webser ul li input.form-control {
                width: 100%;
                margin-bottom: 6px;
                height: 46px;
                font-size: 14px;
                margin-right: 0px;}
            section.techno {
                padding-top: 50px;
                background: #1b5355;
                padding-bottom: 50px;
            }
            .webser ul {
                display: block;
                list-style: none;
                margin: 0 auto;
                padding-left: 0px;
            }
            p.premier br {
                display: none;
            }
            h4.subheading {
             font-size: 16px;
            }
            p.custr {
                margin-bottom: 0px;
                margin-top: 16px;
                text-align: center;
            }
            img.girl {
                margin: 0 auto;
                text-align: center;
                width: 100%;
                display: block;
                margin-bottom: -16px;
            }
        .meaningful {
           text-align: center;
            position: unset;
            top: unset;
          width: 100%;
            }
            .meaningful h3 {
                font-size: 24px;
                line-height: 32px;
                text-align: center;
            }
        div#cta1 {
         padding: 20px 15px;
         width: 100%;
         display: flex;
        flex-direction: column-reverse;
         }
            section.banner-bottom h2 {
             font-size: 30px;
             line-height: 40px;
            }
            .bnrcta {
                margin-top: 0px;
                text-align: center;
            }
            .bnrcta a {
              padding: 15px 22px;
              font-size: 14px;
            }
            .banner-bg {
                padding: 110px 0 50px  !important;
            }
        section.banner-area video {
            object-fit: cover;
            width: 70%;
         }
         span.Masterys {
            font-size: 24px;
            font-weight: 500;
        }
        p.Been {
            display: none;
                }
        .banner-content {
            position: absolute;
            top: 55% !important;
            width: 100%;}
    }
    @media (max-width: 580px) and (min-width: 426px){
      h4 {
        font-size: 20px;
        line-height: 30px;
        }
      .indus-sec .indus-box {
        padding: 20px 0px;}

      .thankyoubox h3 {
        font-size: 22px;
        line-height: 32px;
        font-weight: 700;
        text-transform: capitalize;
    }
      img.thankyou {
        width: 42%;
        margin-bottom: 30px;
    }
      section.thankyou {
        padding-top: 150px;
        padding-bottom: 50px;
    }
      .banner-content .awesome {
        font-size: 36px;
        margin-bottom: 30px;
 }
      section.contactbnr {
        padding-top: 130px;
         }
      .abnr {
        padding-top: 0;
    }
      div#header-row {
        margin-top: 0px;
        margin-bottom: 0;
    }
    .topbar1 {
      display: none;
  }
  .topbar {
    display: none;
}
      section.Privacy-pilicy {
        margin-top: 90px;
 }
      section.policies h2 {
        font-size: 32px;
        margin-bottom: 30px;
        text-align: center;
    }
      section.policies {
        padding-top: 50px;
        padding-bottom: 50px;
    }
      section.obinner {
        padding-top: 70px;
    }
    h1.dgjhj {
      text-transform: capitalize;
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 15px;}

    .acent {
      text-align: center;
  }
  img.gh {
    padding-left: 0px;
    margin-top: 40px;
}
      .conatctcontent {
        padding-top: 0px;
        text-align: center;
    }
    .conatctcontent h1 {
      text-transform: capitalize;
      font-size: 36px;
      line-height: 46px;
      margin-bottom: 0;}

      .conatctcontent p {
        font-size: 16px;
        line-height: 26px;
        color: #000;
        margin-top: 16px;
        margin-bottom: 30px;
    }
    .cont-form {
      padding: 30px 15px 20px 15px;
      margin-top: 30px;
      width: 100%;
  }
  div#fddf {
    margin-top: 30px;
}
.cont-box {
  height: unset;
  margin-bottom: 20px;}
  
  .cont-form ul .form-control {
    display: block;
    width: 100%;
    padding: 12px;
    margin-bottom: 8px;
    line-height: 20px;
    color: #212529;
}
    img.contctbnr {
      margin-top: 30px;
  }
  section.contactdetail {
    padding-top: 50px;
    padding-bottom: 50px;
}

      .acontent {
        padding-top: 0px;
        text-align: center;
    }
    p.custmer1 {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 15px;
      color: #000;
  }
  ul.in-ul li {
    font-size: 16px;
    line-height: 32px;
    padding-right: 10px;
    color: #000;
}
  ul.in-ul {
    padding-left: 0px;
    list-style: none;
    display: block;
    margin-bottom: 25px;
}
section.inner1 {
  padding-bottom: 60px;
}
.led:hover {
  padding: 30px 10px;
}
.led{
  padding: 30px 10px;
  text-align: center;
}
.led h4:after {
  left: 40%;
 }
 section.ib-serivces {
  padding-bottom: 0px;
}
div#scalability {
  margin-top: 35px;
}
img.inboundbnr {
  display: none;}

 h1.dg {
  text-transform: capitalize;
  font-size: 35px;
  margin-bottom: 20px;}
  .rightnew h4 {
    display: grid;
}
img.icee {
  width: 44px;
  margin-right: 0;
  margin-bottom: 20px;
}
img.bann {
  margin-bottom: 30px;
}
section.whyib {
  padding-bottom: 30px;
}
section.ibfeatures {
  position: relative;
  padding-top: 60px;
  padding-bottom: 30px;
}
.sfs .react-tabs__tab {
  font-size: 16px;
  padding: 12px 30px;
  display: block;
}
.Distrution {
  text-align: center;
  margin-top: 40px;
}
.Distrution .bnrcta {
  margin-top: 55px;
  text-align: center;
}
.voice {
  text-align: center;
  margin-bottom: 30px;
  padding: 30px 15px 10px 15px;}


 /* internal page      */
      .row {
        --bs-gutter-x: 0;
    }
    .herobox {
      text-align: center;
  }
  p.Ehires {
    padding-top: 16px;
    margin-bottom: 70px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 50px;
}
  section.work {
    padding-top: 50px;
    padding-bottom: 50px;
    background: #f9f9f9 none repeat scroll 0 0;
}
      section.investment_support {
        display: none;
    }
      span.Saving1 {
        font-size: 26px;
        color: #3eafb7;
    }
      img.morph {
        position: absolute;
        left: 0px;
        bottom: 0;
        z-index: -1;
    }
    .awesome {
      font-size: 60px;
  
    }
    .abnr p {
      font-size: 16px;
      line-height: 26px;
       text-align: center;
       width: 100%;
       margin-top: 24px;
  }
  ul.inl li {
    font-size: 16px;
 }
 ul.inl {
  margin-top: 30px;
}
.abnr .bnrcta {
  margin-top: 40px;
}
section.aboutbner {
  padding-top: 120px;
  padding-bottom: 80px;
  position: relative;
}
.typting {
  padding-left: 0px;
  text-align: center;
  margin-top: 30px;
}
ul.when {
  padding-left: 0;
  list-style: none;
  margin-top: 10px;
  text-align: left;
}
p.containing {
  width: 100%;
  margin: 0 auto;
  font-size: 16px;
  line-height: 26px;
  margin-top: 20px;
  margin-bottom: 0px;
}
.brow p {
  width: 100%;
  font-size: 16px;
}
section.rule {
  padding-top: 50px;
  padding-bottom: 40px;
}

section.easier {
  padding-bottom: 10px;
}

/* our company */
      section.investment_support {
        display: none;
    }
      p.Get {
        margin: 0 auto;
        text-align: center;
        width: 100%;
        margin-top: 24px;
        margin-bottom: 0px;
    }
      p.premier {
        width: 100%;
        margin: 0 auto;
        margin-top: 24px;
    }
      p.caring br {
        display: none;
    }
      ul.when li {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 27px;
    }
      img.star {
        margin-right: 4px;
        width: 19px;
    }
        .banner-content .title {
            font-size: 30px !important;
            margin-bottom: 0px;
            line-height: 40px !important;
            }
            section.banner-bottom h2 br {
                display: none;
            }
     div#cta1:after {
    display: none;
     }
     div#cta1:before{
        display: none;
     }
     .footer-top-two {
        padding: 50px 0 45px !important;
    }
     .meaningful a.btn.wow.fadeInUp {
        margin: 0 auto;
        margin-top: 30px;
        padding: 18px 30px;
        display: block;
        width: 50%;
        margin-bottom: 40px;
    }
     img.orange {
        margin-bottom: 40px;
        margin-top: 0px;
    }
    .accordion-button {
      font-size: 16px;
     line-height: 26px;
     }
  section.stsret {
    padding-top: 50px;
    padding-bottom: 50px;
    }
    .btn {
        padding: 18px 30px;
    }
    p.Get br {
        display: none;
    }
     .who {
        margin-right: 0px;
        text-align: center;
    }
    section.oufrea .bnrcta {
        float: none;
        margin-bottom: 30px;
    }
    .green-underline {
      margin-left: 115px;
    }
    .who p {
        font-size: 16px;
        line-height: 26px;
        margin-top: 20px;
        margin-bottom: 40px;
    }
     section.oufrea {
        padding-top: 50px;
        padding-bottom: 20px;}

     .webser form input.form-rol {
        width: 100%;}
   section.homecta {
     padding-top: 40px;}

            .webser form textarea#message {
             width: 100%;
             padding: 10px;
             height: 80px;
              font-size: 15px;
             margin-right: 0px;
               }
            h2 {
                font-size: 28px;
                text-transform: capitalize;
                line-height: 38px;
            }
            .webser ul li input.form-control {
                width: 100%;
                margin-bottom: 6px;
                height: 46px;
                font-size: 14px;
                margin-right: 0px;}
            section.techno {
                padding-top: 50px;
                background: #1b5355;
                padding-bottom: 50px;
            }
            .webser ul {
                display: block;
                list-style: none;
                margin: 0 auto;
                padding-left: 0px;
            }
            p.premier br {
                display: none;
            }
            h4.subheading {
             font-size: 16px;
            }
            p.custr {
                margin-bottom: 0px;
                margin-top: 16px;
                text-align: center;
            }
            img.girl {
                margin: 0 auto;
                text-align: center;
                width: 100%;
                display: block;
                margin-bottom: -19px;
            }
        .meaningful {
           text-align: left;
            position: unset;
            top: unset;
          width: 100%;
            }
            .meaningful h3 {
                font-size: 24px;
                line-height: 32px;
                text-align: center;
            }
        div#cta1 {
         padding: 20px 15px;
         width: 100%;
         display: flex;
        flex-direction: column-reverse;
         }
            section.banner-bottom h2 {
             font-size: 30px;
             line-height: 40px;
            }
            .bnrcta {
                margin-top: 0px;
                text-align: center;
            }
            .bnrcta a {
              padding: 15px 22px;
              font-size: 14px;
            }
            .banner-bg {
                padding: 110px 0 50px  !important;
            }
        section.banner-area video {
            object-fit: cover;
            width: 70%;
         }
         span.Masterys {
            font-size: 24px;
            font-weight: 500;
        }
        p.Been {
            display: none;
                }
        .banner-content {
            position: absolute;
            top: 55% !important;
            width: 100%;}
        
    }
    @media (max-width: 767px) and (min-width: 581px){
      h4 {
        font-size: 20px;
        line-height: 30px;
        }
      .thankyoubox h3 {
        font-size: 22px;
        line-height: 32px;
        font-weight: 700;
        text-transform: capitalize;
    }
      img.thankyou {
        width: 42%;
        margin-bottom: 30px;
    }
      section.thankyou {
        padding-top: 150px;
        padding-bottom: 50px;
    }
      .banner-content .awesome {
        font-size: 48px;
        margin-bottom: 40px;
        margin-top: 6px;
 }
      section.contactbnr {
        padding-top: 130px;
         }
      .abnr {
        padding-top: 0;
    }
      div#header-row {
        margin-top: 0px;
        margin-bottom: 0;
    }
    .topbar1 {
      display: none;
  }
  .topbar {
    display: none;
}
      section.Privacy-pilicy {
        margin-top: 90px;
 }
      section.policies h2 {
        font-size: 32px;
        margin-bottom: 30px;
        text-align: center;
    }
      section.policies {
        padding-top: 50px;
        padding-bottom: 50px;
    }
      section.obinner {
        padding-top: 70px;
    }
    h1.dgjhj {
      text-transform: capitalize;
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 15px;}

    .acent {
      text-align: center;
  }
  img.gh {
    padding-left: 0px;
    margin-top: 40px;
}
      .conatctcontent {
        padding-top: 0px;
        text-align: center;
    }
    .conatctcontent h1 {
      text-transform: capitalize;
      font-size: 36px;
      line-height: 46px;
      margin-bottom: 0;}

      .conatctcontent p {
        font-size: 16px;
        line-height: 26px;
        color: #000;
        margin-top: 16px;
        margin-bottom: 30px;
    }
    .cont-form {
      padding: 30px 15px 20px 15px;
      margin-top: 30px;
      width: 100%;
  }
  div#fddf {
    margin-top: 30px;
}
.cont-box {
  height: unset;
  margin-bottom: 20px;}
  
  .cont-form ul .form-control {
    display: block;
    width: 100%;
    padding: 12px;
    margin-bottom: 8px;
    line-height: 20px;
    color: #212529;
}
    img.contctbnr {
      margin-top: 30px;
  }
  section.contactdetail {
    padding-top: 50px;
    padding-bottom: 50px;
}

      .acontent {
        padding-top: 0px;
        text-align: center;
    }
    p.custmer1 {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 15px;
      color: #000;
  }
  ul.in-ul li {
    font-size: 16px;
    line-height: 32px;
    padding-right: 10px;
    color: #000;
}
  ul.in-ul {
    padding-left: 0px;
    list-style: none;
    display: block;
    margin-bottom: 25px;
}
section.inner1 {
  padding-bottom: 60px;
}
.led:hover {
  padding: 30px 10px;
}
.led{
  padding: 30px 10px;
  text-align: center;
}
.led h4:after {
  left: 40%;
 }
 section.ib-serivces {
  padding-bottom: 0px;
}
div#scalability {
  margin-top: 35px;
}
img.inboundbnr {
  display: none;}

 h1.dg {
  text-transform: capitalize;
  font-size: 35px;
  margin-bottom: 20px;}
  .rightnew h4 {
    display: grid;
}
img.icee {
  width: 44px;
  margin-right: 0;
  margin-bottom: 20px;
}
img.bann {
  margin-bottom: 30px;
}
section.whyib {
  padding-bottom: 30px;
}
section.ibfeatures {
  position: relative;
  padding-top: 60px;
  padding-bottom: 30px;
}
.sfs .react-tabs__tab {
  font-size: 16px;
  padding: 12px 30px;
  display: block;
}
.Distrution {
  text-align: center;
  margin-top: 40px;
}
.Distrution .bnrcta {
  margin-top: 55px;
  text-align: center;
}
.voice {
  text-align: center;
  margin-bottom: 30px;
  padding: 30px 15px 10px 15px;}


 /* internal page      */
      .row {
        --bs-gutter-x: 0;
    }
    .herobox {
      text-align: center;
  }
  p.Ehires {
    padding-top: 16px;
    margin-bottom: 70px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 50px;
}
  section.work {
    padding-top: 50px;
    padding-bottom: 50px;
    background: #f9f9f9 none repeat scroll 0 0;
}
      section.investment_support {
        display: none;
    }
      span.Saving1 {
        font-size: 26px;
        color: #3eafb7;
    }
      img.morph {
        position: absolute;
        left: 0px;
        bottom: 0;
        z-index: -1;
    }
    .awesome {
      font-size: 60px;
  
    }
    .abnr p {
      font-size: 16px;
      line-height: 26px;
       text-align: center;
       width: 100%;
       margin-top: 24px;
  }
  ul.inl li {
    font-size: 16px;
 }
 ul.inl {
  margin-top: 30px;
}
.abnr .bnrcta {
  margin-top: 40px;
}
section.aboutbner {
  padding-top: 120px;
  padding-bottom: 80px;
  position: relative;
}
.typting {
  padding-left: 0px;
  text-align: center;
  margin-top: 30px;
}
ul.when {
  padding-left: 0;
  list-style: none;
  margin-top: 10px;
  text-align: center;
}
p.containing {
  width: 100%;
  margin: 0 auto;
  font-size: 16px;
  line-height: 26px;
  margin-top: 20px;
  margin-bottom: 0px;
}
.brow p {
  width: 100%;
  font-size: 16px;
}
section.rule {
  padding-top: 50px;
  padding-bottom: 40px;
}

section.easier {
  padding-bottom: 10px;
}

/* our company */
      section.investment_support {
        display: none;
    }
      p.Get {
        margin: 0 auto;
        text-align: center;
        width: 100%;
        margin-top: 24px;
        margin-bottom: 0px;
    }
      p.premier {
        width: 100%;
        margin: 0 auto;
        margin-top: 24px;
    }
      p.caring br {
        display: none;
    }
      ul.when li {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 27px;
    }
      img.morph {
         left: 0px;
    }
    .abnr .bnrcta {
      margin-top: 40px;
  }
  p.containing {
    width: 100%;
    margin: 0 auto;
    font-size: 16px;
    line-height: 26px;}

  .typting {
    padding-left: 0px;
    text-align: center;
    margin-top: 30px;
}
  section.rule {
    padding-top: 60px;
    padding-bottom: 50px;
}
  .brow p {
    width: 100%;
    margin: 0 auto;
    font-size: 16px;
    color: #ffffff;
    line-height: 26px;}
  ul.inl {
  margin-top: 40px;
}
ul.inl li {
  font-size: 16px;
  line-height: 30px;
  padding-right: 5px;
  color: #000;
}
    .abnr p {
      font-size: 16px;
      line-height: 26px;
      width: 100%;}

    section.aboutbner {
      padding-top: 120px;
      padding-bottom: 120px;
      position: relative;
  }
    .awesome {
       font-size: 90px;
   }
        .banner-content .title {
            font-size:46px !important;
            margin-bottom: 0px;
            line-height: 47px !important;
            }
            section.banner-bottom h2 br {
                display: none;
            }
     div#cta1:after {
    display: none;
     }
     div#cta1:before{
        display: none;
     }
     .footer-top-two {
        padding: 50px 0 45px !important;
    }
     .meaningful a.btn.wow.fadeInUp {
        margin: 0 auto;
        margin-top: 30px;
        padding: 18px 30px;
        display: block;
        width: 50%;
        margin-bottom: 40px;
    }
     img.orange {
        margin-bottom: 40px;
        margin-top: 0px;
    }
    .accordion-button {
      font-size: 16px;
     line-height: 26px;
     }
  section.stsret {
    padding-top: 50px;
    padding-bottom: 50px;
    }
    .btn {
        padding: 18px 30px;
    }
    p.Get br {
        display: none;
    }
     .who {
        margin-right: 0px;
        text-align: center;
    }
    section.oufrea .bnrcta {
        float: none;
        margin-bottom: 30px;
    }
    p.caring br {
      display: none;
  }
  section.easier {
    padding-bottom: 30px;
}
.green-underline {
    margin-left: 208px;
}
    .who p {
        font-size: 16px;
        line-height: 26px;
        margin-top: 20px;
        margin-bottom: 40px;
    }
     section.oufrea {
        padding-top: 50px;
        padding-bottom: 20px;}

     .webser form input.form-rol {
        width: 100%;}
   section.homecta {
     padding-top: 40px;}

            .webser form textarea#message {
             width: 100%;
             padding: 10px;
             height: 80px;
              font-size: 15px;
             margin-right: 0px;
               }
            h2 {
                font-size: 28px;
                text-transform: capitalize;
                line-height: 38px;
            }
            .webser ul li input.form-control {
                width: 100%;
                margin-bottom: 6px;
                height: 46px;
                font-size: 14px;
                margin-right: 0px;}
            section.techno {
                padding-top: 50px;
                background: #1b5355;
                padding-bottom: 50px;
            }
            .webser ul {
                display: block;
                list-style: none;
                margin: 0 auto;
                padding-left: 0px;
            }
            p.premier br {
                display: none;
            }
            h4.subheading {
             font-size: 16px;
            }
            p.custr {
                margin-bottom: 0px;
                margin-top: 16px;
                text-align: center;
            }
            img.girl {
                margin: 0 auto;
                text-align: center;
                width: 100%;
                display: block;
                margin-bottom: -24px;
            }
        .meaningful {
           text-align: center;
            position: unset;
            top: unset;
          width: 100%;
            }
            .meaningful h3 {
                font-size: 24px;
                line-height: 32px;
                text-align: center;
            }
            div#cta1 {
                padding: 45px 15px;
                width: 100%;
                display: flex;
        flex-direction: column-reverse;
            }
            section.banner-bottom h2 {
             font-size: 30px;
             line-height: 40px;
            }
            .bnrcta {
                margin-top: 0px;
                text-align: center;
            }
            .bnrcta a {
              padding: 15px 22px;
              font-size: 14px;
            }
            .banner-bg {
                padding: 110px 0 50px  !important;
            }
        section.banner-area video {
            object-fit: cover;
            width: 70%;
         }
         span.Masterys {
            font-size: 28px;
            font-weight: 500;
        }
        p.Been {
            display: none;
                }
        .banner-content {
            position: absolute;
            top: 55% !important;
            width: 100%;}
        
    }
    @media (max-width: 991px) and (min-width: 768px){
      h4 {
        font-size: 20px;
        line-height: 30px;
        }
      .reprehenderit {
        text-align: center;
        margin-bottom: 45px;
    }
      .led {
        transition: ease-out 0.2s;
        padding: 10px 30px;
    }
    .leding {
      margin-top: 20px;
      margin-bottom: 30px;
      border-top: 2px solid;
      padding-top: 20px;
  }
    .Distrution {
      text-align: left;
      margin-top: 0;
  }
      img.thankyou {
        width: 30%;
      }
      .banner-content .awesome {
          font-size: 60px;
         margin-bottom: 38px;
        margin-top: 10px;
    }
      section.contactbnr {
        padding-top: 170px;
    }
      .abnr {
        padding-top: 10px;
    }
      div#header-row {
        margin-top: 0px;
        margin-bottom: 0;
    }
    .topbar1 {
      display: none;
  }
  .topbar {
    display: none;
}

      h1.dgjhj {
        text-transform: capitalize;
        font-size: 32px;
        line-height: 44px;
        margin-bottom: 0;}

        .acent {
          padding-top: 0px;
      }
section.obinner {
    padding-top: 150px;
    padding-bottom: 60px;
}

      .conatctcontent {
        padding-bottom: 60px;
        padding-top: 0px;
    }
    .cont-form {
      padding: 30px 16px 20px 16px;
      width: 100%;
  }
  section.contactdetail {
    padding-top: 60px;
    padding-bottom: 50px;
}
  .cont-box {
    margin-bottom: 20px;
    height: unset;}
  .cont-form ul .form-control {
    display: block;
    width: 100%;
    padding: 8px;}

    .conatctcontent p {
      font-size: 18px;
      line-height: 28px;}

      .acontent {
        padding-top: 10px;
    }
    section.ib-serivces {
       padding-bottom: 0px;
  }
  .rightnew h4 {
    display: grid;
}
    ul.in-ul li {
      font-size: 16px;
      line-height: 36px;
      padding-right: 6px;
      color: #000;
  }
  img.icee {
    width: 44px;
    margin-right: 0px;
    margin-bottom: 20px;
}
  section.inner1 {
  padding-bottom: 65px;
}
  p.custmer1 {
    display: none;}
    img.inboundbnr {
      position: absolute;
      bottom: 10%;
      right: 0;
      width: 46%;
  }
    ul.in-ul {
      margin-top: 20px;
      padding-left: 0px;
      list-style: none;
      display: block;
      margin-bottom: 27px;
  }
  section.whyib {
    padding-bottom: 40px;
    padding-top: 20px;
}
div#keyto {
  display: none;
}
section.ibfeatures {
  position: relative;
  padding-top: 60px;
  padding-bottom: 20px;
}
.sfs .react-tabs__tab {
  font-size: 16px;
  padding: 16px 30px;
}
.Distrution br {
  display: none;
}
    h1.dg {
      text-transform: capitalize;
      font-size: 42px;
      margin-bottom: 10px;}



      .row {
        --bs-gutter-x: 0;
    }
      span.Saving1 {
        color: #3eafb7;
    }
    section.work {
      padding-top: 60px;
      padding-bottom: 60px;
      background: #f9f9f9 none repeat scroll 0 0;
  }
   
      /* our company */
      .herobox h2.text-white {
        font-size: 28px;
    }

      section.investment_support {
        display: none;
    }
      p.Get {
        margin: 0 auto;
        text-align: center;
        width: 100%;
        margin-top: 24px;
        margin-bottom: 0px;
    }
      p.premier {
        width: 90%;
        margin: 0 auto;
        margin-top: 24px;
    }
      section.homecta {
        padding-top: 35px;
        padding-bottom: 50px;
        position: relative;
        background: #f9f9f9 none repeat scroll 0 0;
    }
      .abnr p {
        font-size: 18px;
        line-height: 28px;
        width: 84%;}

        section.aboutbner {
          padding-top: 130px;
          padding-bottom: 100px;
          position: relative;
      }
      .investment_support .wrapper.active .item:nth-child(4) .head h4 span {
        transition-delay: 4.6s;
        margin-left: 40px;
    }
      section.easier {
        padding-bottom: 30px;
    }
    .investment_support .wrapper.active .item .head h4 span {
      margin-left: 10px;
      transition-delay: 2.4s;
  }
  .investment_support .wrapper .item .item_wrap {
    padding: 40px 10px;}
      .typting ul.when li {
        line-height: 36px;
    }
    p.containing {
      width: 80%;
      margin: 0 auto;
      font-size: 16px;
      line-height: 26px;}
    .investment_support .head h4 {
      color: #fff;
      font-size: 20px;}
    p.caring br {
      display: none;
  }
      .brow p {
        width: 85%;}
        section.rule {
          padding-top: 70px;
          padding-bottom: 60px;
      }
      .typting ul.when {
        margin-top: 0px;
        margin-bottom: 0px;
    }
      .awesome {
       font-size: 90px;
        }
    img.morph {
          left: 0;
       }
    img.headphone1 {
      width: 60%;
    }
      h2 {
        font-size: 35px;
        text-transform: capitalize;
        line-height: 45px;
    }
    ul.when li {
      margin-bottom: 0px;
      font-size: 16px;
  }
  section.homecta a.btn.wow.fadeInUp {
    margin-top: 0px;
    margin-bottom: 30px;
}
.footer-top-two {
  padding: 60px 0 50px;
}
section.stsret {
  padding-top: 60px;
  padding-bottom: 60px;
}
.accordion-button {
  font-size: 18px;
}
p.Get {
  margin-top: 26px;
}
p.Get br {
  display: none;
}
img.orange {
  margin-bottom: 40px;
  margin-top: 0px;
}
.green-underline {
  margin-left: 304px;
}
section.oufrea .bnrcta {
  float: none;
  margin-bottom: 30px;
}
.who p {
  font-size: 16px;
  line-height: 26px;
  margin-top: 25px;
}
img.girl {
  margin: 0 auto;
  text-align: center;
  width: 50%;
  display: block;
  margin-bottom: -16px;
}
.who {
  margin-right: 0px;
  text-align: center;
}
p.premier br {
  display: none;
}
        .webser ul {
            display: block;
            padding-left: 0px;
        }
        span.Masterys {
            font-size: 36px;
            font-weight: 500;
        }
        div#cta1 {
        padding: 20px 10px;
        width: 100%;
               }
   .banner-content {
    width: 100%;
        }
    section.banner-bottom h2 {
      color: #fff;
      font-size: 35px;
      text-transform: capitalize;
      line-height: 50px;
        }
    section.banner-area video {
       object-fit: cover;
        width: 70%;}

        p.Been {
            text-align: center;
            font-size: 16px;
            line-height: 26px;
            font-weight: 300;
            margin-top: 35px;
            width: 77%;
            margin: 0 auto;
        }
        .banner-content .title {
          font-size: 59px;
          margin-bottom: 0px;
          line-height: 60px;
      }


    .webser form textarea#message {
      width: 100%;
      padding: 10px;
      height: 110px;
      margin-bottom: 18px;}

    .webser ul li input.form-control {
       width: 100%;
       margin-bottom: 10px;}  
    }
    @media (max-width: 1023px) and (min-width: 992px){
      .banner-content .awesome {
              font-size: 58px;
           margin-bottom: 38px;
        margin-top: 16px;
    }
      h1.dgjhj {
        text-transform: capitalize;
        font-size: 35px;
        line-height: 48px;
        margin-bottom: 30px;   }

        .acent {
          padding-top: 0px;
      }
section.obinner {
    padding-top: 180px;
    padding-bottom: 0;
}

      .conatctcontent {
        padding-bottom: 50px;
        padding-top: 50px;
    }
    section.contactbnr {
     padding-top: 160px;
  }
    .cont-form {
      padding: 30px 16px 20px 16px;
      width: 100%;
  }
  section.contactdetail {
    padding-top: 60px;
    padding-bottom: 50px;
}
  .cont-box {
    margin-bottom: 20px;
    height: unset;}
  .cont-form ul .form-control {
    display: block;
    width: 100%;
    padding: 8px;}

    .conatctcontent p {
      font-size: 18px;
      line-height: 28px;}
      .acontent {
        padding-top: 30px;
    }
    section.ib-serivces {
       padding-bottom: 0px;
  }
  .rightnew h4 {
    display: grid;
}
    ul.in-ul li {
      font-size: 16px;
      line-height: 36px;
      padding-right: 6px;
      color: #000;
  }
  img.icee {
    width: 44px;
    margin-right: 0px;
    margin-bottom: 20px;
}
  section.inner1 {
  padding-bottom: 65px;
}
  p.custmer1 {
    display: none;}
    img.inboundbnr {
      position: absolute;
      bottom: 0%;
      right: 0;
      width: 46%;
  }
    ul.in-ul {
      margin-top: 20px;
      padding-left: 0px;
      list-style: none;
      display: block;
      margin-bottom: 27px;
  }
  section.whyib {
    padding-bottom: 40px;
    padding-top: 20px;
}
div#keyto {
  display: none;
}
section.ibfeatures {
  position: relative;
  padding-top: 60px;
  padding-bottom: 20px;
}
.sfs .react-tabs__tab {
  font-size: 16px;
  padding: 16px 30px;
}
.Distrution br {
  display: none;
}
    h1.dg {
      text-transform: capitalize;
      font-size: 53px;
      margin-bottom: 10px;}
      p.caring br {
        display: none;
    }
      section.heromobile {
        display: none;
    }
      p.Get {
        margin: 0 auto;
        text-align: center;
        width: 75%;
        margin-top: 24px;
        margin-bottom: 0px;
    }
      p.premier {
        width: 67%;
        margin: 0 auto;
        margin-top: 24px;
    }
      img.morph {
        position: absolute;
        left: 0px;
        bottom: 0;
        z-index: -1;
    }

    img.headphone1 {
     width: 45%;
  }
    .abnr p {
      font-size: 18px;
      line-height: 28px;}

    section.aboutbner {
      padding-top: 150px;
      padding-bottom: 110px;
      position: relative;
  }
    p.Ehires {
      padding-top: 16px;
      margin-bottom: 70px;
      width: 74%;
      margin: 0 auto;
      padding-bottom: 70px;
  }
    .reprehenderit {
      text-align: center;
      margin-top: 15px;
  }
    .typting ul.when li {
      line-height: 36px;
  }
  p.containing {
    font-size: 18px;
    line-height: 28px;
  }
    .typting ul.when {
      margin-top: 10px;
      margin-bottom: 0px;
  }
      .investment_support .head h4 {
        color: #fff;
        font-size: 26px;}
      h2 {
        font-size: 35px;
        text-transform: capitalize;
        line-height: 45px;
    }
    ul.when li {
      margin-bottom: 0px;
      font-size: 16px;
  }
  section.homecta a.btn.wow.fadeInUp {
    margin-top: 0px;
    margin-bottom: 30px;
}
.footer-top-two {
  padding: 60px 0 50px;
}
section.stsret {
  padding-top: 60px;
  padding-bottom: 60px;
}
.accordion-button {
  font-size: 18px;
}
p.Get {
  margin-top: 26px;
}
p.Get br {
  display: none;
}
img.orange {
  margin-bottom: 40px;
  margin-top: 0px;

}
section.oufrea {
  padding-top: 60px;
  padding-bottom: 40px;}
  

.green-underline {
  margin-left: 108px;
}
section.oufrea .bnrcta {
  float: none;
  margin-bottom: 30px;
}
.who p {
  font-size: 16px;
  line-height: 26px;
  margin-top: 25px !important;
  width: 80%;
  margin: 0 auto;
}
img.girl {
  margin: 0 auto;
  text-align: center;
  width: 50%;
  display: block;
  margin-bottom: -22px;
}
.who {
  margin-right: 0px;
  text-align: center;
}
p.premier br {
  display: none;
}
        .webser ul {
            display: block;
            padding-left: 0px;
        }
        span.Masterys {
            font-size: 36px;
            font-weight: 500;
        }
        div#cta1 {
        padding: 20px 10px;
        width: 100%;
               }
   .banner-content {
    width: 100%;
        }
    section.banner-bottom h2 {
      color: #fff;
      font-size: 35px;
      text-transform: capitalize;
      line-height: 50px;
        }
        section.banner-area video {
          object-fit: cover;
          width: 57%;
      }

        p.Been {
            text-align: center;
            font-size: 16px;
            line-height: 26px;
            font-weight: 300;
            margin-top: 35px;
            width: 77%;
            margin: 0 auto;
        }
        .banner-content .title {
          font-size: 59px;
          margin-bottom: 0px;
          line-height: 60px;
      }


    .webser form textarea#message {
      width: 100%;
      padding: 10px;
      height: 110px;
      margin-bottom: 18px;}

    .webser ul li input.form-control {
       width: 100%;
       margin-bottom: 10px;}  
    }
    @media (max-width: 1199px) and (min-width: 1024px){
      section.aboutbner {
        padding-top: 120px;
        padding-bottom: 100px;
        position: relative;
    }
    .leding {
      margin-top: 20px;
      margin-bottom: 30px;
      border-top: 2px solid;
      padding-top: 20px;
  }
  .Distrution {
    text-align: left;
    margin-top: 0px;
}
    section.ibfeatures {
      padding-bottom: 30px;
  }
    .led {
      transition: ease-out 0.2s;
      padding: 30px 0px;
  }
    img.process1 {
      width: 50%;}
    .brow p {
      width: 80%;}
    .abnr p {
       width: 80%;
      margin-top: 35px;
      padding-bottom: 30px;
  }
    img.headphone1 {
      width: 45%;
  }
    h1.awesome {
      font-size: 100px;
  }
      div#header-row {
        margin-top: 0;
        margin-bottom: 0;
    }
    marquee {
      text-align: center;
      color: #fff !important;
      float: none;
      font-size: 14px;
      letter-spacing: 1px;
  }
      .banner-content .awesome {
        font-size: 58px;
     margin-bottom: 38px;
  margin-top: 16px;
}
      h1.dgjhj {
        text-transform: capitalize;
        font-size: 35px;
        line-height: 48px;
        margin-bottom: 30px;
    }

        .acent {
          padding-top: 0px;
      }
section.obinner {
  padding-top: 235px;
  padding-bottom: 60px;
}
      .cont-box {
        margin: 10px;
        height: 240px;}

        .conatctcontent {
          padding-top: 0px;
      }
      section.contactbnr {
        padding-top: 230px;
    }
    .cont-form {
      padding: 40px 20px 20px 20px;}

      h1.dg {
        text-transform: capitalize;
        font-size: 38px;
        line-height: 52px;}
        
  p.custmer1 {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px;
      color: #000;
      } 
      img.inboundbnr {
        position: absolute;
        bottom: 0;
        right: 2%;
        width: 48%;
    }
    section.whyib {
      padding-bottom: 40px;
      padding-top: 20px;
  }
    section.ib-serivces {
      padding-bottom: 0px;
  }
      ul.in-ul {
        padding-left: 0px;
        list-style: none;
        display: block;
        margin-bottom: 30px;
    } 
    ul.in-ul li {
      font-size: 16px;
      padding-right: 0px;
      color: #000;
      line-height: 36px;
  }  
      
      

    
    p.caring br {
      display: none;
  }
      section.heromobile {
        display: none;
    }
      p.premier {
        width: 67%;
        margin: 0 auto;
        margin-top: 24px;
    }

      .reprehenderit {
        text-align: center;
        margin-bottom: 40px;
    }
      .investment_support .head h4 {
        color: #fff;
        font-size: 27px;}
      p.containing {
        width: 80%;
        margin: 0 auto;
        font-size: 18px;
        line-height: 28px;}

      img.morph {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
    }
    section.easier {
      padding-bottom: 50px;
  }
    section.rule {
      padding-top: 100px;
      padding-bottom: 70px;
  }
    .typting ul.when {
     margin-top: 15px;
  }
    .typting ul.when li {
      margin-bottom: 0px;
      font-size: 16px;
      line-height: 36px;
  }
      h2 {
        font-size: 35px;
        text-transform: capitalize;
        line-height: 45px;
    }
    ul.when li {
      margin-bottom: 0px;
      font-size: 16px;
  }
  section.homecta a.btn.wow.fadeInUp {
    margin-top: 0px;
    margin-bottom: 30px;
}
.footer-top-two {
  padding: 60px 0 50px;
}
section.stsret {
  padding-top: 60px;
  padding-bottom: 60px;
}
.accordion-button {
  font-size: 18px;
}
p.Get {
  margin-top: 26px;
}
p.Get br {
  display: none;
}
img.orange {
  margin-bottom: 40px;
  margin-top: 0px;

}
section.oufrea {
  padding-top: 60px;
  padding-bottom: 40px;}
  

.green-underline {
  margin-left: 108px;
}
section.oufrea .bnrcta {
  float: none;
  margin-bottom: 30px;
}
.who p {
  font-size: 16px;
  line-height: 26px;
  margin-top: 25px !important;
  width: 80%;
  margin: 0 auto;
}
img.girl {
  margin: 0 auto;
  text-align: center;
  width: 50%;
  display: block;
  margin-bottom: -22px;
}
.who {
  margin-right: 0px;
  text-align: center;
}
p.premier br {
  display: none;
}
 .webser ul  {
  display: inline-flex;
   padding-left: 0px;
        }
        span.Masterys {
            font-size: 36px;
            font-weight: 500;
        }
        div#cta1 {
        padding: 20px 10px;
        width: 80%;
               }
   .banner-content {
    width: 100%;
        }
    section.banner-bottom h2 {
      color: #fff;
      font-size: 35px;
      text-transform: capitalize;
      line-height: 50px;
        }
        section.banner-area video {
          object-fit: cover;
          width: 57%;
      }

        p.Been {
            text-align: center;
            font-size: 16px;
            line-height: 26px;
            font-weight: 300;
            margin-top: 35px;
            width: 77%;
            margin: 0 auto;
        }
        .banner-content .title {
          font-size: 59px;
          margin-bottom: 0px;
          line-height: 60px;
      }


    .webser form textarea#message {
      width: 100%;
      padding: 10px;
      height: 46px;
      margin-bottom: 18px;}

    .webser ul li input.form-control {
       width: 100%;
       margin-bottom: 10px;}  
    }
    @media (max-width: 1300px) and (min-width: 1200px){
      .banner-content .awesome {
        font-size: 58px;
        margin-bottom: 38px;
        margin-top: 16px;
    }
      .acent {
        padding-top: 0;
    }
    section.obinner {
      padding-top: 218px;
  }
    h1.dgjhj {
      text-transform: capitalize;
      font-size: 35px;
      line-height: 45px;}


      img.contctbnr {
        width: 85%;
        float: right;
    }
    .conatctcontent p {
      font-size: 20px;
      line-height: 30px;}
      .cont-box {
        margin: 10px;
        height: 240px;}

        .conatctcontent {
          padding-top: 50px;
      }
      section.contactbnr {
        padding-top: 220px;
    }
    .cont-form {
      padding: 40px 20px 20px 20px;}
      h1.dg {
        text-transform: capitalize;
        font-size: 38px;
        line-height: 52px;}
        
  p.custmer1 {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px;
      color: #000;
      } 
      img.inboundbnr {
        position: absolute;
        bottom: 0;
        right: 2%;
        width: 48%;
    }
    section.whyib {
      padding-bottom: 40px;
      padding-top: 20px;
  }
    section.ib-serivces {
      padding-bottom: 0px;
  }
      ul.in-ul {
        padding-left: 0px;
        list-style: none;
        display: block;
        margin-bottom: 30px;
    } 
    ul.in-ul li {
      font-size: 16px;
      padding-right: 0px;
      color: #000;
      line-height: 36px;
  }  
      
      p.caring br {
        display: none;
    }
      .row {
        --bs-gutter-x: 0;
    }
      section.heromobile {
            display: none;
    }
      img.morph {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
    }
    section.rule {
      padding-top: 100px;
      padding-bottom: 70px;
  }
    .typting ul.when {
     margin-top: 15px;
  }
    .typting ul.when li {
      margin-bottom: 0px;
      font-size: 16px;
      line-height: 36px;
  }
      h2 {
        font-size: 35px;
        text-transform: capitalize;
        line-height: 45px;
    }
    ul.when li {
      margin-bottom: 0px;
      font-size: 16px;
  }
  section.homecta a.btn.wow.fadeInUp {
    margin-top: 0px;
    margin-bottom: 30px;
}
.footer-top-two {
  padding: 60px 0 50px;
}
section.stsret {
  padding-top: 60px;
  padding-bottom: 60px;
}
.accordion-button {
  font-size: 18px;
}
p.Get {
  width: 72%;
  margin: 0 auto;
  margin-top: 24px;
}
p.Get br {
  display: none;
}
img.orange {
  margin-bottom: 40px;

}
section.oufrea {
  padding-top: 60px;
  padding-bottom: 40px;}
  

.green-underline {
  margin-left: 108px;
}
section.oufrea .bnrcta {
  float: none;
  margin-bottom: 30px;
}
.who p {
  font-size: 16px;
  line-height: 26px;
  margin-top: 25px !important;
  width: 65%;
  margin: 0 auto;
}
img.girl {
  margin: 0 auto;
  text-align: center;
  width: 34%;
  display: block;
  margin-bottom: -25px;
}
.who {
  margin-right: 0px;
  text-align: center;
}
p.premier br {
  display: none;
}
p.premier {
  width: 60%;
  margin: 0 auto;
  margin-top: 24px;
}
 .webser ul {
   display: inline-flex;
   padding-left: 0px;
        }
        span.Masterys {
            font-size: 36px;
            font-weight: 500;
        }
        div#cta1 {
          padding: 20px 40px;
          width: 80%;
               }
   .banner-content {
    width: 100%;
        }
    section.banner-bottom h2 {
      color: #fff;
      font-size: 35px;
      text-transform: capitalize;
      line-height: 50px;
        }
        section.banner-area video {
          object-fit: cover;
          width: 40%;
      }

        p.Been {
            text-align: center;
            font-size: 16px;
            line-height: 26px;
            font-weight: 300;
            margin-top: 35px;
            width: 43%;
            margin: 0 auto;
        }
        .banner-content .title {
          font-size: 59px;
          margin-bottom: 0px;
          line-height: 60px;
      }
      .webser form textarea#message {
        width: 100%;
        padding: 10px;
        height: 46px;
        margin-bottom: 18px;
    }

    .webser ul li input.form-control {
       width: 100%;
       margin-bottom: 10px;} 
    }
    @media (max-width: 1399px) and (min-width: 1301px){
      p.Designing {
        padding-right: 30px;}
      .banner-content .awesome {
        font-size: 58px;
        margin-bottom: 38px;
        margin-top: 16px;
    }
      .acent {
        padding-top: 0;
    }
    section.obinner {
      padding-top: 220px;
  }
    h1.dgjhj {
      text-transform: capitalize;
      font-size: 42px;
      line-height: 56px;}

      img.contctbnr {
        width: 85%;
        float: right;
    }
    .conatctcontent p {
      font-size: 20px;
      line-height: 30px;}
      .cont-box {
        margin: 10px;
        height: 240px;}

        .conatctcontent {
          padding-top: 50px;
      }
      section.contactbnr {
        padding-top: 220px;
    }
    .cont-form {
      padding: 40px 20px 20px 20px;}
      h1.dg {
        text-transform: capitalize;
        font-size: 38px;
        line-height: 52px;}
        
  p.custmer1 {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 16px;
      color: #000;
      } 
      img.inboundbnr {
        position: absolute;
        bottom: 0;
        right: 2%;
        width: 48%;
    }
    section.whyib {
      padding-bottom: 40px;
      padding-top: 20px;
  }
    section.ib-serivces {
      padding-bottom: 0px;
  }
      ul.in-ul {
        padding-left: 0px;
        list-style: none;
        display: block;
        margin-bottom: 30px;
    } 
    ul.in-ul li {
      font-size: 16px;
      padding-right: 0px;
      color: #000;
      line-height: 36px;
  }  
      
      .row {
        --bs-gutter-x: 30px;
    }
      section.heromobile {
          display: none;
    }
      img.morph {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
    }
    section.rule {
      padding-top: 100px;
      padding-bottom: 70px;
  }
    .typting ul.when {
     margin-top: 15px;
  }
    .typting ul.when li {
      margin-bottom: 0px;
      font-size: 16px;
      line-height: 36px;
  }

      h2 {
        font-size: 35px;
        text-transform: capitalize;
        line-height: 45px;
    }
    ul.when li {
      margin-bottom: 0px;
      font-size: 16px;
  }
  section.homecta a.btn.wow.fadeInUp {
    margin-top: 0px;
    margin-bottom: 30px;
}
.footer-top-two {
  padding: 60px 0 50px;
}
section.stsret {
  padding-top: 60px;
  padding-bottom: 60px;
}
.accordion-button {
  font-size: 18px;
}
p.Get {
  width: 72%;
  margin: 0 auto;
  margin-top: 24px;
}
p.Get br {
  display: none;
}
img.orange {
  margin-bottom: 40px;

}
section.oufrea {
  padding-top: 60px;
  padding-bottom: 40px;}

  div#Emphires {
    margin-top: 80px;
}

.green-underline {
  margin-left: 152px;
}
section.oufrea .bnrcta {
  float: none;
  margin-bottom: 30px;
}
.who p {
  font-size: 16px;
  line-height: 26px;
  margin-top: 25px !important;
  width: 65%;
  margin: 0 auto;
}
img.girl {
  margin: 0 auto;
  text-align: center;
  width: 40%;
  display: block;
  margin-bottom: -29px;
}
.who {
  margin-right: 0px;
  text-align: center;
}
p.premier br {
  display: none;
}
p.premier {
  width: 60%;
  margin: 0 auto;
  margin-top: 24px;
}
 .webser ul {
   display: inline-flex;
   padding-left: 0px;
        }
        span.Masterys {
            font-size: 36px;
            font-weight: 500;
        }
        div#cta1 {
        padding: 20px 50px;
        width: 80%;
               }
   .banner-content {
    width: 100%;
        }
    section.banner-bottom h2 {
      color: #fff;
      font-size: 35px;
      text-transform: capitalize;
      line-height: 50px;
        }
        section.banner-area video {
          object-fit: cover;
          width: 40%;
      }

        p.Been {
            text-align: center;
            font-size: 16px;
            line-height: 26px;
            font-weight: 300;
            margin-top: 35px;
            width: 43%;
            margin: 0 auto;
        }
        .banner-content .title {
          font-size: 59px;
          margin-bottom: 0px;
          line-height: 60px;
      }
      .webser form textarea#message {
        width: 100%;
        padding: 10px;
        height: 46px;
        margin-bottom: 18px;
    }

    .webser ul li input.form-control {
       width: 100%;
       margin-bottom: 10px;} 
    }
    @media (max-width: 1440px) and (min-width: 1400px){
      p.Designing {
        color: #fff;
        margin-bottom: 30px;
        margin-top: 20px;
        padding-right: 40px;
    }
      section.aboutbner {
        padding-top: 110px;
        padding-bottom: 100px;
        position: relative;
    }
      .banner-content .awesome {
        font-size: 65px;
        margin-bottom: 38px;
        margin-top: 16px;
    }
      .acent {
        padding-top: 15px;
    }
    section.obinner {
      padding-top: 200px;
  }
  .acent ul.in-ul li {
    font-size: 18px;}
  .acent p.custmer1 {
    font-size: 20px;
    line-height: 32px;}
  h1.dgjhj {
    text-transform: capitalize;
    font-size: 48px;
    line-height: 60px;
}
      img.contctbnr {
        width: 85%;
        float: right;
    }
    img.gentlman0 {
      width: 76%;
      margin: 0 auto;
      display: block;
      border-radius: 5px;
      float: right;
  }
    .conatctcontent p {
      font-size: 22px;
      line-height: 32px;}
      .cont-box {
        margin: 10px;
        height: 240px;}

        .conatctcontent {
          padding-top: 50px;
      }
      section.contactbnr {
        padding-top: 220px;
    }
    .cont-form {
      padding: 40px 20px 20px 20px;}
      h1.dg {
        text-transform: capitalize;
        font-size: 48px;
        line-height: 58px;}
        .acontent {
          padding-top: 35px;
      }
  p.custmer1 {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 30px;
      color: #000;
      } 
      img.inboundbnr {
        position: absolute;
        bottom: 0;
        right: 2%;
        width: 40%;
    }
    section.whyib {
      padding-bottom: 40px;
      padding-top: 20px;
  }
    section.ib-serivces {
      padding-bottom: 0px;
  }
      ul.in-ul {
        padding-left: 0px;
        list-style: none;
        display: block;
        margin-bottom: 30px;
    } 
    ul.in-ul li {
      font-size: 16px;
      padding-right: 0px;
      color: #000;
      line-height: 36px;
  }  
      
      .row {
        --bs-gutter-x: 0x;
    }
      section.heromobile {
            display: none;
    }
      img.morph {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
    }
    section.rule {
      padding-top: 100px;
      padding-bottom: 70px;
  }
    .typting ul.when {
     margin-top: 15px;
  }
    .typting ul.when li {
      margin-bottom: 0px;
      font-size: 16px;
      line-height: 36px;
  }

      /* aboutus */
      h2 {
        font-size: 35px;
        text-transform: capitalize;
        line-height: 45px;
    }
    ul.when li {
      margin-bottom: 0px;
      font-size: 16px;
  }
  section.homecta a.btn.wow.fadeInUp {
    margin-top: 0px;
    margin-bottom: 30px;
}
.footer-top-two {
  padding: 60px 0 50px;
}
img.ctagir {
  width: 85%;
}.webser form input.form-rol {
  margin-left: 2px;}
.meaningful {
  text-align: left;
  padding-top: 30px;
  padding-left: 30px;
}
section.stsret {
  padding-top: 60px;
  padding-bottom: 60px;
}
.accordion-button {
  font-size: 18px;
}
p.Get {
  width: 52%;
  margin: 0 auto;
  margin-top: 24px;
}
p.Get br {
  display: none;
}
img.orange {
  margin-bottom: 40px;

}
section.oufrea {
  padding-top: 60px;
  padding-bottom: 40px;}
  

  .green-underline {
    margin-left: 160px;
}
section.oufrea .bnrcta {
  float: right;
  margin-bottom: 30px;
}
.who p {
  font-size: 16px;
  line-height: 26px;
  margin-top: 25px !important;
  width: 100%;
  margin: 0 auto;
}
img.girl {
  margin: 0 auto;
  text-align: center;
  width: 34%;
  display: block;
  margin-bottom: -20px;
}
.who {
  margin-right: 0px;
  text-align: left;
}
p.premier br {
  display: none;
}
p.premier {
  width: 60%;
  margin: 0 auto;
  margin-top: 24px;
}
 .webser ul {
   display: inline-flex;
   padding-left: 0px;
        }
        span.Masterys {
          font-size: 44px;
          font-weight: 500;
          text-shadow: unset !important;
      }
        div#cta1 {
        padding: 20px 10px;
        width: 80%;
               }
   .banner-content {
    width: 100%;
        }
    section.banner-bottom h2 {
      color: #fff;
      font-size: 35px;
      text-transform: capitalize;
      line-height: 50px;
        }
        section.banner-area video {
          object-fit: cover;
          width: 45%;
      }

      p.Been {
        text-align: center;
        font-size: 18px;
        line-height: 28px;
        font-weight: 300;
        width: 43%;
        margin: 0 auto;
        margin-top: 26px;
    }
        .banner-content .title {
          font-size: 80px;
          margin-bottom: 0px;
          line-height: 72px;
          margin-bottom: 10px;
      }
      .webser form textarea#message {
        width: 100%;
        padding: 10px;
        height: 46px;
        margin-bottom: 18px;
        border-radius: 5px;
    }

    section.indus-sec .row.no-gutters.m-0 div#Unknown:nth-child(3){
      border-bottom: 1px solid #ddd;
    border-right: 0px;
    padding: 0;
    }
    section.indus-sec .row.no-gutters.m-0 div#Unknown:nth-child(6){
      border-bottom: 1px solid #ddd;
    border-right: 0px;
    padding: 0;
    }
    .webser ul li input.form-control {
       width: 100%;
       margin-bottom: 10px;} 
    }
    @media (max-width: 1600px) and (min-width: 1441px){
      .acent {
        padding-top: 0px;
    }
    section.obinner {
      padding-top: 250px;
  }
  .acent ul.in-ul li {
    font-size: 17px;}

  .acent p.custmer1 {
    font-size: 20px;
    line-height: 32px;}
  h1.dgjhj {
    text-transform: capitalize;
    font-size: 45px;
  }
      section.heromobile {
             display: none;
    }
    img.morph {
      position: absolute;
      left: 0px;
      bottom: 0;
      z-index: -1;
  }
    }
    @media (max-width: 2500px) and (min-width: 1600px){
      section.heromobile {
          display: none;
    }
    }

